import { Injectable } from '@angular/core';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class StudiesService extends CentralService {

  protected resource: string = "studies";

}
