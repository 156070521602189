import { ProjectsService } from './../../../services/resources/projects';
import { NgForm } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { SquareBracketsEncoder } from 'src/encoders/square-brakets-encoder';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  @ViewChild('closeModalBtn', {static: false}) closeModalBtn: ElementRef | undefined;

  isPageLoading: boolean = false
  isModalLoading: boolean = false

  itemsPerPage: number = 15;
  currentPage: number = 1;
  data: any[] = [];
  totalItems: number = 0

  isEdit: boolean = false
  activeId: number = -1
  specificItem: any = {}
  defaultSpecificItem: any = {country: {id: undefined}, sector: {id: undefined}, city: {id: undefined}}

  filterBy: string = 'name'
  filterText: string = ''

  isPasswordShown: boolean = false

  constructor(private defaultService: ProjectsService) {

   }

  ngOnInit(): void {
    this.getAll(1)
    if(!this.isEdit){
      this.specificItem = this.defaultSpecificItem
    }
  }

  indexing(i: number) {
    return (((this.currentPage - 1) * this.itemsPerPage) + (i + 1))
  }
  
  getAll(page: number, filter?: any): void {
    this.isPageLoading = true

    let params: HttpParams = new HttpParams({
        encoder: new SquareBracketsEncoder()
    });

    params = params.append("perPage", this.itemsPerPage)    
    params = params.append("page", page)
    params = params.append("sort", 'country_id')

    if(filter) {
      let filtersArray = Object.entries(filter)
      for(let i of filtersArray) {
        let filterKey = i[0]
        let filterValue = String(i[1])
        params = params.append(`filter[${filterKey}]`, filterValue)
      }      
    }

    this.defaultService.getAll(params)
    .pipe(finalize(()=> { this.isPageLoading = false}))
    .subscribe(res => {
      this.currentPage = page
      this.data = res['data']
      this.totalItems = res['meta']['total']
    })
  }

  getSpecific(id: number): void {
    this.isModalLoading = true
    this.defaultService.getSpecific(id)
    .pipe(finalize(()=> { this.isModalLoading = false}))
    .subscribe(res => {
      this.specificItem = res['data']
    })
  }

  changePage(page: number): void {
    this.getAll(page)
  }

  filter(searchForm: NgForm): void {
    if(searchForm.value['searchInput']) {
      this.getAll(1, {name: searchForm.value['searchInput']})
    }
  }


  delete(id: number): void {
    if (confirm("هل انت متأكد من رغبتك بحذف العنصر؟")) {
      this.defaultService.delete(id).subscribe(
        () => {
          this.getAll(this.currentPage)
        }
      )
    }
  }

}
