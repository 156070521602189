import { HttpHeaders } from '@angular/common/http';
import { Injectable, Pipe } from '@angular/core';
import { Observable } from 'rxjs';
import { CentralService } from '../central.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class AttachmentsService extends CentralService {
  protected resource: string = "attachments";


  getAttachment(link: string, params?: any): Observable <Blob> {
    let  headers= new HttpHeaders({
      'Content-Type':  'application/pdf',
    })
    return this.http.get(link, {headers:headers , responseType: 'blob' })
  }

}
