<div class="row align-items-center">
  <div class="col-lg-7 text-center text-md-end">
    <h3 class="mt-4 mb-0">
      <a routerLink="../" class="link-dark"> الشركات </a>>
      <h3 class="mt-4 mb-0 d-inline">
        <a routerLink="./" class="link-dark"> بيانات الشركات </a>
      </h3>
    </h3>
    <p [class.invisible]="!specificItem?.registration_step">
      <small>الخطوة الحالية: {{ specificItem?.registration_step }}</small>
    </p>
  </div>
  <div class="col-lg-5 text-lg-start text-center">
    <h3 class="mt-4 mb-0">
      <div class="dropdown-center d-inline-block">
        <button class="btn btn-outline-dark rounded-pill ms-3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          خيارات
        </button>
        <ul class="dropdown-menu py-0 mt-3 shadow-sm hover-shadow rounded">
          <li>
            <button
              class="dropdown-item d-inline-flex py-3 text-success border-bottom"
              data-bs-toggle="modal"
              data-bs-target="#acceptionModal"              
              >
              <i class="bi bi-check hstack ps-1"></i>
              قبول
            </button>
          </li>
          <li>
            <button
            class="dropdown-item d-inline-flex py-3 text-danger border-bottom"
            data-bs-toggle="modal"
            data-bs-target="#rejectionModal"
            >
              <i class="bi bi-x hstack ps-1"></i>
              رفض
            </button>
          </li>
          <li>
            <button
              class="dropdown-item d-inline-flex py-3"
              data-bs-toggle="modal"
              data-bs-target="#resetStepsModal"
            >
              <i class="bi bi-x hstack ps-1"></i>
              إعادة التعيين
            </button>
          </li>
  
        </ul>
      </div>      
      <!-- <button
        class="btn btn-outline-success rounded-pill py-1 ms-3 d-inline-flex pe-2"
        data-bs-toggle="modal"
        data-bs-target="#acceptionModal"
        
      >
        <i class="bi bi-check hstack ps-1"></i>
        قبول
      </button>
      <button
        class="btn btn-outline-danger rounded-pill py-1 ms-3 d-inline-flex pe-2"
        data-bs-toggle="modal"
        data-bs-target="#rejectionModal"
      >
        <i class="bi bi-x hstack ps-1"></i>
        رفض
      </button>
      <button
      class="btn btn-outline-danger rounded-pill py-1 ms-3 d-inline-flex pe-2"
      data-bs-toggle="modal"
      data-bs-target="#resetStepsModal"
    >
      <i class="bi bi-x hstack ps-1"></i>
      إعادة التعيين
    </button> -->
      <a
        class="btn btn-outline-dark rounded-pill py-1 ms-3 d-none d-md-inline pe-2"
        routerLink="print"
        target="_blank"
      >
        <i class="bi bi-printer ps-1"></i>
        طباعة
      </a>
    </h3>
  </div>
</div>
<hr />
<div class="row">
  <div class="col-12">
    <div
      class="card hover-shadow shadow-sm mb-5"
      style="border-radius: 10px !important"
    >
      <div class="card-body py-lg-4 px-lg-3 px-0">
        <ng-container *ngIf="isPageLoading">
          <div class="text-center py-5">
            <div class="spinner-grow" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <div [class.d-none]="isPageLoading">
            <ng-container>
              <div class="container">
                <div
                  class="row text-center mb-5 pb-0 border-bottom align-items-center"
                >
                  <div class="col-12 col-lg-6 text-lg-end mb-3 mb-lg-4">
                    <h4 class="mb-0">
                      <small>{{ specificItem?.company_name }}</small>
                    </h4>
                  </div>
                  <div class="col-12 col-lg-6 text-lg-start mb-4">
                    <h5 class="mb-0">
                      <span
                        class="badge border border-dark text-dark rounded-pill px-3 py-2"
                      >
                        <small>{{ specificItem?.status?.name }}</small>
                      </span>
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-4 col-xxl-3 mb-3">
                    <h5 class="mb-0">
                      <small class="d-block mb-2">الرقم المرجعي</small>
                      <span
                        class="badge border border-dark text-dark rounded w-100 py-2"
                      >
                        {{ specificItem?.reference_number }}
                      </span>
                    </h5>
                  </div>
                  <div class="col-12 col-md-4 col-xxl-3 mb-3">
                    <h5 class="mb-0">
                      <small class="d-block mb-2">نوع النشاط</small>
                      <span
                        class="badge border border-dark text-dark rounded w-100 py-2"
                      >
                        {{ specificItem?.activity_type?.name }}
                      </span>
                    </h5>
                  </div>
                  <div class="col-12 col-md-4 col-xxl-3 mb-3">
                    <h5 class="mb-0">
                      <small class="d-block mb-2">الرقم الآلي</small>
                      <span
                        class="badge border border-dark text-dark rounded w-100 py-2"
                      >
                        {{ specificItem?.registration_code }}
                      </span>
                    </h5>
                  </div>
                  <div class="col-12 col-md-4 col-xxl-3 mb-3">
                    <h5 class="mb-0">
                      <small class="d-block mb-2">تاريخ التسجيل</small>
                      <span
                        class="badge border border-dark text-dark rounded w-100 py-2"
                      >
                        {{ specificItem?.created_at }}
                      </span>
                    </h5>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-lg-4">
                    <div class="row mb-3 pb-4">
                      <div class="col-12 mb-2">
                        <h5 class="border-bottom pb-3">
                          <small>البيانات الأساسية</small>
                        </h5>
                      </div>
                      <div class="col-12 text-end my-2">
                        <h5>
                          <small>تاريخ التأسيس: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{
                              specificItem?.basic_info?.establishing_date
                            }}</span
                          >
                        </h5>
                      </div>
                      <div class="col-12 text-end my-2">
                        <h5>
                          <small>سنوات الخبرة:</small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{
                              specificItem?.basic_info?.years_of_experience
                            }}</span
                          >
                        </h5>
                      </div>
                      <div class="col-12 text-end my-2">
                        <h5>
                          <small>عدد الشركاء:</small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{
                              specificItem?.basic_info?.stockholders_number
                            }}</span
                          >
                        </h5>
                      </div>
                      <div class="col-12 col-md-12 text-end my-2">
                        <h5>
                          <small>التخصصات: </small>
                          <ng-container
                            *ngFor="
                              let item of specificItem?.basic_info?.specialties
                            "
                          >
                            <small class="mx-1">
                              <span
                                class="badge badge bg-dark rounded-pill d-inline"
                              >
                                {{ item?.is_other ? item?.other : item?.name }}
                              </span>
                            </small>
                          </ng-container>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="row mb-3 pb-4">
                      <div class="col-12 mb-2">
                        <h5 class="border-bottom pb-3">
                          <small>البيانات المالية</small>
                        </h5>
                      </div>
                      <div class="col-12 text-end my-2">
                        <h5>
                          <small>قيمة رأس المال المكتتب به: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{
                              specificItem?.financial_info
                                ?.capital_subscribed_amount
                            }}</span
                          >
                        </h5>
                      </div>
                      <div class="col-12 text-end my-2">
                        <h5>
                          <small>قيمة رأس المال المدفوع: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{
                              specificItem?.financial_info?.capital_paid_amount
                            }}</span
                          >
                        </h5>
                      </div>
                      <div class="col-12 text-end my-2">
                        <h5>
                          <small>المصرف: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{ specificItem?.financial_info?.bank_name }}</span
                          >
                        </h5>
                      </div>
                      <div class="col-12 text-end my-2">
                        <h5>
                          <small>الفرع: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{
                              specificItem?.financial_info?.bank_branch
                            }}</span
                          >
                        </h5>
                      </div>
                      <div class="col-12 text-end my-2">
                        <h5>
                          <small>رقم الحساب: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{
                              specificItem?.financial_info?.account_number
                            }}</span
                          >
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="row mb-3 pb-4">
                      <div class="col-12 mb-2">
                        <h5 class="border-bottom pb-3">
                          <small>بيانات المفوض</small>
                        </h5>
                      </div>
                      <div class="col-12 text-end my-2">
                        <h5>
                          <small>الاسم:</small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{ specificItem?.delegate_name }}</span
                          >
                        </h5>
                      </div>
                      <div class="col-12 text-end my-2">
                        <h5>
                          <small>الصفة القانونية:</small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{ specificItem?.delegate_adjective }}</span
                          >
                        </h5>
                      </div>
                      <div class="col-12 text-end my-2">
                        <h5>
                          <small>رقم الهاتف:</small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{ specificItem?.delegate_phone }}</span
                          >
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="row mb-3 pb-4">
                      <div class="col-12 mb-2">
                        <h5 class="border-bottom pb-3">
                          <small>بيانات إضافية</small>
                        </h5>
                      </div>
                      <div class="col-12 col-md-4 text-end my-2">
                        <h5>
                          <small>الواتس اب: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                          >
                            <a
                              target="_blank"
                              [href]="
                                'https://wa.me/' +
                                specificItem?.additional_info?.whatsapp_phone
                              "
                            >
                              {{
                                specificItem?.additional_info?.whatsapp_phone
                              }}
                            </a>
                          </span>
                        </h5>
                      </div>
                      <div class="col-12 col-md-4 text-end my-2">
                        <h5>
                          <small>هاتف الشركة: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                          >
                            <a
                              target="_blank"
                              [href]="
                                'tel:' +
                                specificItem?.additional_info?.company_phone
                              "
                            >
                              {{ specificItem?.additional_info?.company_phone }}
                            </a>
                          </span>
                        </h5>
                      </div>
                      <div class="col-12 col-md-4 text-end my-2">
                        <h5>
                          <small>البريد الإلكتروني: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                          >
                            <a
                              [href]="
                                'mailto:' +
                                specificItem?.additional_info?.company_email
                              "
                            >
                              {{ specificItem?.additional_info?.company_email }}
                            </a>
                          </span>
                        </h5>
                      </div>
                      <div class="col-12 col-md-4 text-end my-2">
                        <h5>
                          <small>الموقع الإلكتروني: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                          >
                            <a target="_blank">
                              {{ specificItem?.additional_info?.website }}
                            </a>
                          </span>
                        </h5>
                      </div>
                      <div class="col-12 col-md-4 text-end my-2">
                        <h5>
                          <small>المفوض العام: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{
                              specificItem?.additional_info
                                ?.delegate_general_office
                            }}</span
                          >
                        </h5>
                      </div>
                      <div class="col-12 col-md-4 text-end my-2">
                        <h5>
                          <small>اثبات هوية المفوض: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            >{{
                              specificItem?.additional_info?.delegate_id_card
                            }}</span
                          >
                        </h5>
                      </div>
                      <div class="col-12 col-md-12 text-end my-2">
                        <h5>
                          <small>العنوان: </small>
                          <span
                            class="badge badge text-dark rounded w-100 d-inline px-3"
                            style="white-space: normal"
                            >{{
                              specificItem?.additional_info?.company_address
                            }}</span
                          >
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="row mb-3 pb-4">
                      <div class="col-12 mb-2">
                        <h5 class="pb-2">
                          <small>الكادر الفني</small>
                        </h5>
                        <div class="table-responsive">
                          <table
                            class="table table-hover text-center table-lg border"
                          >
                            <thead>
                              <tr>
                                <th class="border-top-0 border-right-0"></th>
                                <th>المهندسين</th>
                                <th>الفنيين</th>
                                <th>الإداريين</th>
                                <th>العمالة العادية</th>
                                <th>الإجمالي</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>العمالة الوطنية</th>
                                <td>
                                  {{
                                    specificItem?.artistic_staff
                                      ?.national_engineers
                                  }}
                                </td>
                                <td>
                                  {{
                                    specificItem?.artistic_staff
                                      ?.national_technicians
                                  }}
                                </td>
                                <td>
                                  {{
                                    specificItem?.artistic_staff
                                      ?.national_administrators
                                  }}
                                </td>
                                <td>
                                  {{
                                    specificItem?.artistic_staff
                                      ?.national_workers
                                  }}
                                </td>
                                <td>
                                  {{
                                    specificItem?.artistic_staff
                                      ?.total_national_workers
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>العمالة الوافدة</th>
                                <td>
                                  {{
                                    specificItem?.artistic_staff
                                      ?.expatriate_engineers
                                  }}
                                </td>
                                <td>
                                  {{
                                    specificItem?.artistic_staff
                                      ?.expatriate_technicians
                                  }}
                                </td>
                                <td>
                                  {{
                                    specificItem?.artistic_staff
                                      ?.expatriate_administrators
                                  }}
                                </td>
                                <td>
                                  {{
                                    specificItem?.artistic_staff
                                      ?.expatriate_workers
                                  }}
                                </td>
                                <td>
                                  {{
                                    specificItem?.artistic_staff
                                      ?.total_expatriate_workers
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <h5 class="pb-2">
                          <small>المرفقات</small>
                        </h5>
                        <div class="table-responsive">
                          <table
                            class="table table-hover text-center table-lg border mb-0"
                          >
                            <thead>
                              <tr>
                                <th class="border-top-0 border-right-0">#</th>
                                <th>اسم المرفق</th>
                                <th>العمليات</th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container
                                *ngIf="specificItem?.attachments?.length == 0"
                              >
                                <td colspan="3">
                                  <div
                                    class="alert bg-transparent mb-0"
                                    style="border-radius: 10px !important"
                                  >
                                    <small>لا توجد مرفقات لعرضها</small>
                                  </div>
                                </td>
                              </ng-container>
                              <ng-container
                                *ngIf="specificItem?.attachments?.length > 0"
                              >
                                <ng-container
                                  *ngFor="
                                    let item of specificItem.attachments;
                                    let i = index
                                  "
                                >
                                  <tr>
                                    <th>
                                      {{ i + 1 }}
                                    </th>
                                    <td>
                                      {{ item?.title }}
                                    </td>
                                    <td>
                                      <button
                                        class="btn btn-outline-primary btn-sm px-3 rounded-lg ms-2"
                                        [disabled]="isGettingAttachment"
                                        (click)="getAttachment(item, 'view')"
                                        data-bs-toggle="modal"
                                        data-bs-target="#attachmentModal"
                                      >
                                        عرض
                                      </button>
                                      <button
                                        class="btn btn-outline-primary btn-sm px-3 rounded-lg"
                                        [disabled]="isGettingAttachment"
                                        (click)="
                                          getAttachment(item, 'download')
                                        "
                                        data-bs-toggle="modal"
                                        data-bs-target="#attachmentModal"
                                      >
                                        تحميل
                                      </button>
                                    </td>
                                  </tr>
                                </ng-container>
                              </ng-container>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="attachmentModal"
  tabindex="-1"
  aria-labelledby="attachmentModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="attachmentModalLabel">
          الرجاء الإنتظار ...
        </h5>
        <button
          type="button"
          class="btn-close me-auto ms-0"
          data-bs-dismiss="modal"
          aria-label="Close"
          #closeModalBtn
        ></button>
      </div>
      <div class="modal-body">
        <div class="text-center py-5">
          <div class="spinner-grow" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
        <p class="text-center">
          <small>يتم الآن تجهيز الملف، الرجاء الإنتظار ...</small>
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Attachment Modal -->
<div
  class="modal fade"
  id="attachmentModal"
  tabindex="-1"
  aria-labelledby="attachmentModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="attachmentModalLabel">
          الرجاء الإنتظار ...
        </h5>
        <button
          type="button"
          class="btn-close me-auto ms-0"
          data-bs-dismiss="modal"
          aria-label="Close"
          #closeModalBtn
        ></button>
      </div>
      <div class="modal-body">
        <div class="text-center py-5">
          <div class="spinner-grow" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
        <p class="text-center">
          <small>يتم الآن تجهيز الملف، الرجاء الإنتظار ...</small>
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Acception Modal -->
<div
  class="modal fade"
  id="acceptionModal"
  tabindex="-1"
  aria-labelledby="acceptionModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="acceptionModalLabel">
          قبول الشركة
        </h5>
        <button
          type="button"
          class="btn-close me-auto ms-0"
          data-bs-dismiss="modal"
          aria-label="Close"
          #closeAcceptionModalBtn
        ></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="isModalLoading">
          <div class="text-center py-5">
            <div class="spinner-grow" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isModalLoading">
          <h5 class="text-center mt-4" style="line-height: 1.6em;">
            هل أنت متأكد من رغبتك بقبول شركة <span class="text-success">{{specificItem?.company_name}}</span>؟
          </h5>
          <div class="mt-4 mb-2 text-center">
            <small>
              <span class="text-danger">*</span>
              بمجرد قبولك للشركة سيتم اصدار رقم آلي ولن يمكنك التراجع
            </small>
          </div>
        </ng-container>
      </div>
      <div class="modal-footer justify-content-start">
        <button type="button" class="btn btn-success" (click)="updateStatus('accept')">نعم، متأكد</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">لا</button>
      </div>      
    </div>
  </div>
</div>

<!-- Rejection Modal -->
<div
  class="modal fade"
  id="rejectionModal"
  tabindex="-1"
  aria-labelledby="rejectionModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="rejectionModalLabel">
          رفض الشركة
        </h5>
        <button
          type="button"
          class="btn-close me-auto ms-0"
          data-bs-dismiss="modal"
          aria-label="Close"
          #closeRejectionModalBtn
        ></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="isModalLoading">
          <div class="text-center py-5">
            <div class="spinner-grow" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isModalLoading">
          <h5 class="text-center mt-4" style="line-height: 1.6em;">
            هل أنت متأكد من رغبتك برفض شركة <span class="text-danger">{{specificItem?.company_name}}</span>؟
          </h5>
          <div class="mt-4 mb-2 text-center">
            <small>
              <span class="text-danger">*</span>
              بمجرد رفضك للشركة لن تتمكن من التراجع
            </small>
          </div>
        </ng-container>
      </div>
      <div class="modal-footer justify-content-start">
        <button type="button" class="btn btn-danger" (click)="updateStatus('reject')">نعم، متأكد</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">لا</button>
      </div>      
    </div>
  </div>
</div>

<!-- Reset Steps Modal -->
<div
  class="modal fade"
  id="resetStepsModal"
  tabindex="-1"
  aria-labelledby="resetStepsModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="resetStepsModalLabel">
          تصفير الخطوات
        </h5>
        <button
          type="button"
          class="btn-close me-auto ms-0"
          data-bs-dismiss="modal"
          aria-label="Close"
          #closeResetStepsModalBtn
        ></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="isModalLoading">
          <div class="text-center py-5">
            <div class="spinner-grow" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isModalLoading">
          <h5 class="text-center mt-4" style="line-height: 1.6em;">
            هل أنت متأكد من رغبتك بإعادة ضبط خطوات شركة <span class="text-danger">{{specificItem?.company_name}} إلى الخطوة الأولى</span>؟
          </h5>
          <div class="mt-4 mb-2 text-center">
            <small>
              <span class="text-danger">*</span>
              بمجرد تصفير الخطوات لن تتمكن من التراجع
            </small>
          </div>
        </ng-container>
      </div>
      <div class="modal-footer justify-content-start">
        <button type="button" class="btn btn-danger" (click)="resetSteps()">نعم، متأكد</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">لا</button>
      </div>      
    </div>
  </div>
</div>