<div class="row">
  <div class="col-12">
    <h3 class="mt-4 mb-0">
      <a routerLink="../" class="link-dark"> المشاريع </a>>
      <h3 class="mt-4 mb-0 d-inline">
        <a routerLink="./" class="link-dark">
          {{ isEdit ? "تعديل مشروع" : "إضافة مشروع" }}</a
        >
      </h3>
    </h3>
  </div>
</div>
<hr />
<div class="row">
  <div class="col-12 text-start mb-3"></div>
  <div class="col-12">
    <div
      class="card hover-shadow shadow-sm position-relative"
      style="border-radius: 10px !important"
    >
    <ng-container *ngIf="isPageLoading">
      <div class="text-center position-absolute" style="width: 100%; height: 100%; z-index: 1111; top: 0; 
      left: 0">
      <div class="card h-100">
        <div class="card-body h-100 d-flex align-items-center justify-content-center">
          <div class="spinner-grow" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
      </div>
    </ng-container>
      <form
        ngNativeValidate
        #addEditForm="ngForm"
        (ngSubmit)="onSubmit(addEditForm)"
        autocomplete="off"
      >
        <div class="card-body">
          <ng-container>
            <div>
              <div class="row g-4 px-lg-5 py-lg-3">
                <div class="col-12">
                  <label
                    class="w-100 py-5 rounded border"
                    style="background: #eff0f2; cursor: pointer"
                  >
                    <img
                      style="height: 75px !important"
                      src="assets/img/no-img.jpg"
                      class="img-fluid mx-auto d-block object-fit-cover"
                      alt="اختر الصورة"
                    />
                    <h6 class="text-center text-black-50">
                      <small>اختر صورة</small>
                    </h6>
                    <input
                      style="cursor: pointer"
                      class="form-control form-control-sm w-75 mx-auto mt-4"
                      type="file"
                      accept="image/*"
                      (change)="appendAttachment($event)"
                      [required]="!isEdit"
                    />
                  </label>
                </div>
                <div class="col-lg-4">
                  <label class="w-100">
                    القطاع
                    <app-dynamic-select
                      [resource]="'projects/sectors'"
                      [cssClasses]="'form-select mt-2'"
                      [optionText]="'name'"
                      [optionValue]="'value'"
                      [(ngModel)]="specificItem.sector['value']"
                      [currentValue]="specificItem?.sector?.value"
                      *ngIf="specificItem"
                      #sector="ngModel"
                      name="sector"
                      ngDefaultControl
                    ></app-dynamic-select>
                  </label>
                </div>
                <div class="col-lg-4">
                  <label class="w-100">
                    المدينة
                    <app-dynamic-select
                      [resource]="'cities'"
                      [cssClasses]="'form-select mt-2'"
                      [optionText]="'name'"
                      [optionValue]="'id'"
                      [(ngModel)]="specificItem.city.id"
                      [currentValue]="specificItem?.city?.id"
                      *ngIf="specificItem"
                      #city_id="ngModel"
                      name="city_id"
                      ngDefaultControl
                    ></app-dynamic-select>
                  </label>
                </div>
                <div class="col-lg-4">
                  <label class="w-100">
                    الحالة
                    <app-dynamic-select
                      [resource]="'projects/statuses'"
                      [cssClasses]="'form-select mt-2'"
                      [optionText]="'name'"
                      [optionValue]="'value'"
                      [(ngModel)]="specificItem.status['value']"
                      [currentValue]="specificItem?.status?.value"
                      *ngIf="specificItem"
                      #status="ngModel"
                      name="status"
                      ngDefaultControl
                    ></app-dynamic-select>
                  </label>
                </div>
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-12 my-3">
                      <label class="w-100">
                        العنوان (عربي)
                        <input
                          type="text"
                          class="form-control mt-2"
                          name="title_ar"
                          [(ngModel)]="specificItem['title_ar']"
                          #title_ar="ngModel"
                          required
                        />
                      </label>
                    </div>
                    <div class="col-12 my-3">
                      <label class="w-100">
                        المحتوى (عربي)
                        <textarea
                          type="text"
                          class="form-control mt-2"
                          name="content_ar"
                          [(ngModel)]="specificItem['content_ar']"
                          #content_ar="ngModel"
                          required
                          rows="10"
                        ></textarea>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-12 my-3">
                      <label class="w-100">
                        العنوان (English)
                        <input
                          type="text"
                          dir="ltr"
                          class="form-control mt-2"
                          name="title_en"
                          [(ngModel)]="specificItem['title_en']"
                          #title_en="ngModel"
                          required
                        />
                      </label>
                    </div>
                    <div class="col-12 my-3">
                      <label class="w-100">
                        المحتوى (English)
                        <textarea
                          type="text"
                          dir="ltr"
                          class="form-control mt-2"
                          name="content_en"
                          [(ngModel)]="specificItem['content_en']"
                          #content_en="ngModel"
                          required
                          rows="10"
                        ></textarea>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      العنوان على الخريطة
                    </div>
                    <div class="card-body p-0">
                      <app-leaf-map [mapHeight]="'60vh'" (selectedCoord)="selectedCoordsChanged($event)" [long]="specificItem?.long" [lat]="specificItem?.lat"></app-leaf-map>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-lg-6">
                        <label class="w-100">
                            خط العرض
                            <input type="text" class="form-control mt-2" name="latitude"
                                [(ngModel)]="specificItem['latitude']" #latitude="ngModel">
                        </label>
                    </div> 
                    <div class="col-lg-6">
                      <label class="w-100">
                          خط الطول
                          <input type="text" class="form-control mt-2" name="longitude"
                              [(ngModel)]="specificItem['longitude']" #longitude="ngModel">
                      </label>
                  </div>  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="card-footer">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="isPageLoading"
          >
            {{ isEdit ? "تعديل" : "إضافة" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
