<div class="d-flex" id="wrapper">

    <!-- Sidebar -->
    <div class="shadow" id="sidebar-wrapper" [class.sidebar-shown] = "toggleStatus" [class.sidebar-hidden] = "!toggleStatus && !isMouseOvered">
      <!--  (mouseover)="isMouseOvered=true;toggleStatus=true" (mouseout)="isMouseOvered=false;toggleStatus=false" -->
      <div class="sidebar-heading py-2">
          <h6 class="text-center">
            <span class="d-block pt-3">
              <a routerLink="/">
                <img src="assets/img/logo.png" [ngClass]="toggleStatus ? ' w-25' : ' w-50'" class="img-fluid mt-3 transition-fast scale-up-hover">
              </a>

              <div class="mt-4">
                <ng-container *ngIf="!isProfileLoading; else profileLoading">
                  <a routerLink="profile">
                    <small class="px-2 d-block" [title]="userProfile?.name">
                      <i class="bi bi-person"></i> {{userProfile?.name}}
                    </small>
                  </a>
                </ng-container>              
                <ng-template #profileLoading>
                  <small style="font-size: 0.85em;">جاري التحميل ...</small>                  
                </ng-template>
              </div>
            </span>
          </h6>
      </div>
      <div class="list-group list-group-flush mt-4 pe-1 overflow-y-auto overflow-x-hidden pb-4" style="max-height:75vh">
        <a title="الرئيسية" routerLink="/dashboard" routerLinkActive="active" (click)="mobileNavigateThenCloseSidebar()" [routerLinkActiveOptions]="{exact: true}" class="list-group-item list-group-item-action my-2 mx-auto rounded">
          <i class="bi bi-columns-gap ps-2 icon"></i>
          <span class="text">الرئيسية</span>
        </a>
        <ng-container *ngFor="let item of menuItems">
          <a [title]="item?.custom_title || item?.display_name" [routerLink]="item.link" routerLinkActive="active" (click)="mobileNavigateThenCloseSidebar()" class="list-group-item list-group-item-action my-2 mx-auto rounded">
            <i class="ps-2 icon " [ngClass]="item?.icon"></i>
            <span class="text">{{item?.display_name}}</span>
          </a>
        </ng-container>
      </div>
      <div class="z-index-2" style="position:absolute;right:0;bottom:0;width:100%;">
        <button class="btn btn-logout border-top w-100 py-3" (click)="logout()" title="تسجيل الخروج"><small class="transition-fast d-block w-100 h-100"><i class="bi bi-power ps-2"></i> <span class="text">تسجيل الخروج</span></small></button>
      </div>
    </div>
    <!-- /#sidebar-wrapper -->
  
    <!-- Page Content -->
    <div id="page-content-wrapper">
      
      <nav id="navbar" [class.hide-navbar]="toggleStatus" class="navbar navbar-expand-lg navbar-light pt-4">
        <div class="container-fluid px-xl-5">
          <button class="btn btn-secondary hover-shadow border-0 shadow-sm pb-0 pt-1 pb-0 px-2 d-inline" (click)="toggleStatus = !toggleStatus">
            <i class="bi" [ngClass]="toggleStatus ? 'bi-x-lg' : 'bi-list'"></i>
          </button>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <img src="assets/img/system-logo.png" width="200" class="img-fluid rounded-pill px-1 py-1">

              <!-- <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="assets/img/user_pic.png" class="border rounded-circle ball transition-fast" width="30" height="30" title="اسم المستخدم" alt="اسم المستخدم">
                <small class="px-2" title="اسم المستخدم">{{(isProfileLoading ? 'جاري التحميل ...' : userProfile?.name)}}</small>
              </a> -->
            </li>
          </ul>
        </div>
      </nav>
      <div id="mainContainer" class="container-fluid px-lg-5 py-lg-2" (click)="sidebarDismissed()">
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- /#page-content-wrapper -->
  
  </div>
  <!-- /#wrapper -->