<div class="container-fluid">
  <div class="row gy-4 mb-5">
      <div class="col-lg-12">
        <div class="container">
            <div class="row gy-4 gy-lg-2 mt-4">
                <div class="col-xl-12 px-0">
                    <ng-template #loader>
                        <div style="height: 70vh;" class="text-center d-flex align-items-center justify-content-center">
                            <app-spinner></app-spinner>
                        </div>
                    </ng-template> 
                    <ng-container *ngIf="!isProfileLoading; else loader">
                    
                  <div class="card shadow-sm rounded-3 hover-shadow transition-fast hover-img-show-hide">
                          <div class="card-body px-4 py-4 rounded-3 bg-primary text-center">                           
                                  <div class="row align-items-center">
                                      <div class="col-3 col-lg-1 text-end">
                                          <div id="profileContainer">
                                              <i class="bi bi-person"></i>
                                          </div>
                                      </div>
                                      <div class="col-9 col-lg-11 text-end text-light">
                                          <div class="mx-4">
                                              <p class="mb-0"><b>{{userProfile?.name}}</b></p>
                                              <p class="mb-1"><a class="link-light" [href]="'mailto:' + userProfile?.email">{{userProfile?.email}}</a></p>
                                              <small class="text-white-50">تاريخ الإنشاء: {{userProfile?.created_at}}</small>
                                          </div>
                                      </div>
                                  </div>
                          </div>
                      </div>
                    </ng-container>
                </div>
                <ng-container *ngIf="!isProfileLoading">
                    <div class="col-12 mt-5">
                      <h4>الصلاحيات: </h4>
                      <hr>                   
                    </div>
                    <div class="col-12">
                      <div class="row gy-2">
                          <ng-container *ngFor="let item of userProfile.permissions">
                              <div class="col-6 col-lg-3">
                                  <h5 class="w-100">
                                      <span class="badge border text-tumblr py-3 border-primary rounded-3 d-block">{{item.name}}</span>
                                  </h5>                        
                              </div>
                          </ng-container>
                      </div>
                    </div>
                </ng-container>              
            </div>
        </div>
      </div>
  </div>
</div>
