import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaSplit'
})
export class CommaSplitPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]) {
   return value.split(',');
  }

}
