import { ProjectsComponent } from './pages/projects/projects.component';
import { NewsComponent } from './pages/media-center/news/news.component';
import { ReportsComponent } from './pages/media-center/reports/reports.component';
import { StudiesComponent } from './pages/media-center/studies/studies.component';
import { PrintCompanyDetailsComponent } from './pages/companies/print-company-details/print-company-details.component';
import { CompanyDetailsComponent } from './pages/companies/company-details/company-details.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpErrorInterceptor } from 'src/services/interceptor.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DynamicSelectComponent } from './components/dynamic-select/dynamic-select.component';
import { CommaSplitPipe } from './pipes/comma-split.pipe';
import { TypeofPipe } from './pipes/typeof.pipe';
import { UploaderComponent } from './components/uploader/uploader.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CompaniesSheetComponent } from './pages/companies/companies-sheet/companies-sheet.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CitiesComponent } from './pages/configurations/cities/cities.component';
import { ConfigurationsIndexComponent } from './pages/configurations/configurations-index/configurations-index.component';
import { PrintContactBoxDetailsComponent } from './pages/contact-box/print-contact-box-details/print-contact-box-details.component';
import { ContactBoxDetailsComponent } from './pages/contact-box/contact-box-details/contact-box-details.component';
import { ContactBoxSheetComponent } from './pages/contact-box/contact-box-sheet/contact-box-sheet.component';
import { MediaIndexComponent } from './pages/media-center/media-index/media-index.component';
import { VideoGalleryComponent } from './pages/media-center/video-gallery/video-gallery.component';
import { PhotoGalleryComponent } from './pages/media-center/photo-gallery/photo-gallery.component';
import { DecisionsComponent } from './pages/media-center/decisions/decisions.component';
import { LeafMapComponent } from './components/leaf-map/leaf-map.component';
import { PtojectAddEditComponent } from './pages/projects/project-add-edit/project-add-edit.component';
import { AboutUsComponent } from './pages/media-center/about-us/about-us.component';

@NgModule({
  declarations: [
    AppComponent,
    DynamicSelectComponent,
    SpinnerComponent,
    UploaderComponent,
    LeafMapComponent,
    LoginComponent,
    ProfileComponent,
    DashboardLayoutComponent,
    DashboardComponent,
    CompaniesSheetComponent,
    CompanyDetailsComponent,
    PrintCompanyDetailsComponent,

    AboutUsComponent,
    ProjectsComponent,
    PtojectAddEditComponent,
    MediaIndexComponent,
    VideoGalleryComponent,
    PhotoGalleryComponent,
    NewsComponent,
    StudiesComponent,
    ReportsComponent,
    DecisionsComponent,
    
    ContactBoxSheetComponent,
    ContactBoxDetailsComponent,
    PrintContactBoxDetailsComponent,
    CitiesComponent,
    ConfigurationsIndexComponent,

    CommaSplitPipe,
    TypeofPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    NgxPaginationModule,
    // NgChartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { 
      provide: LocationStrategy, 
      useClass: HashLocationStrategy 
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

