<div class="row">
    <div class="col-6">
        <h3 class="mt-4 mb-0">
          <a routerLink="../" class="link-dark"> إدارة المحتوى </a>>
            <h3 class="mt-4 mb-0 d-inline">
              <a routerLink="./" class="link-dark">
                معرض الفيديو
                <div>
                </div>
              </a>
            </h3>
        </h3>
      </div>
    <div class="col-6 text-start">
        <button (click)="openModal(isEdit= false)"
            class="btn hover-shadow rounded-pill px-3 btn-sm btn-outline-dark ms-2 mt-4 hover-shadow" data-bs-toggle="modal"
            data-bs-target="#addEditModal" title="إضافة">
            <i class="bi bi-plus-lg pt-2 ps-2"></i>
            إضافة جديد
        </button>
    </div>
</div>
<hr>
<div class="row">
    <div class="col-12">
        <div class="card hover-shadow shadow-sm" style="border-radius: 10px !important">
            <div class="card-body p-0">
                <ng-container *ngIf="isPageLoading">
                    <div class="text-center py-5">
                        <div class="spinner-grow" role="status"><span class="sr-only"></span></div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isPageLoading && data.length == 0">
                    <div class="alert alert-warning mb-0" style="border-radius: 10px !important">
                        <i class="bi bi-info-circle ps-2"></i>
                        عذرًا، ولكن لا توجد بيانات لعرضها.
                    </div>
                </ng-container>

                <ng-container *ngIf="!isPageLoading && data.length != 0">
                    <div class="container">
                        <div class="row">
                            <ng-container *ngFor="let i = index; let item of data | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }">
                                <div class="col-6 col-lg-3 text-center my-4">
                                    <a class="cursor-pointer">
                                        <div class="position-relative shadow-sm p-3">
                                            <img class="img-fluid mb-3" [src]="returnPreviewLink(item?.attachment)" [alt]="item?.title" [title]="item?.title" style="height: 27vh;object-fit: cover;width: 100%" (click)="openModal(isEdit = true, item['id'])" data-bs-toggle="modal" data-bs-target="#addEditModal">
                                            <h6 class="mb-0" (click)="openModal(isEdit = true, item['id'])" data-bs-toggle="modal" data-bs-target="#addEditModal">{{item?.title}}</h6>
                                            <p class="text-muted" (click)="openModal(isEdit = true, item['id'])" data-bs-toggle="modal" data-bs-target="#addEditModal"><small style="font-size: 0.7em;">{{item?.updated_at}}</small></p>
                                            <div
                                            style="
                                              position: absolute;
                                              left: 10px !important;
                                              top: 10px !important;
                                            "
                                          >
                                            <button
                                              class="btn btn-sm rounded-circle btn-danger d-block font-weight-normal pt-1"
                                              title="حذف"
                                              style="width: 30px; height: 30px;"
                                              (click)="delete(item.id)"
                                            >
                                              <i class="bi bi-trash"></i>
                                            </button>
                                          </div>
                                        </div>
                                    </a>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    
                    <div class="text-center mt-4">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-6 text-end">
                                    <pagination-controls (pageChange)="changePage($event)" previousLabel="السابق"
                                        nextLabel="التالي"></pagination-controls>
                                </div>
                                <div class="col-6 text-start">
                                    الإجمالي: {{totalItems}}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<form ngNativeValidate #addEditForm = "ngForm" (ngSubmit)="onModalSubmit(addEditForm)">
    <div class="modal fade" id="addEditModal" tabindex="-1" aria-labelledby="addEditModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addEditModalLabel">{{isEdit ? 'تعديل' : 'إضافة'}}</h5>
                    <button type="button" class="btn-close me-auto ms-0" data-bs-dismiss="modal" aria-label="Close" #closeModalBtn></button>
                </div>
                <div class="modal-body">
                    <ng-container *ngIf="isModalLoading">
                        <div class="text-center py-5">
                            <div class="spinner-grow" role="status"><span class="sr-only"></span></div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isModalLoading">
                        <div class="row g-4 px-lg-5 py-lg-3">                                                     
                            <div class="col-lg-12">
                                <label class="w-100">
                                    عنوان الفيديو (عربي)
                                    <input type="text" class="form-control mt-2" name="title_ar"
                                        [(ngModel)]="specificItem['title_ar']" #title_ar="ngModel" required>
                                </label>
                            </div>
                            <div class="col-lg-12">
                                <label class="w-100">
                                    عنوان الفيديو  (English)
                                    <input type="text" dir="ltr" class="form-control mt-2" name="title_en"
                                        [(ngModel)]="specificItem['title_en']" #title_en="ngModel" required>
                                </label>
                            </div>
                            <div class="col-lg-12">
                                <label class="w-100">
                                    رابط الفيديو من اليوتيوب
                                    <input type="url" dir="ltr" class="form-control mt-2" name="attachment"
                                        [(ngModel)]="specificItem['attachment']" #attachment="ngModel" required>
                                </label>
                            </div>                                             
                        </div>

                    </ng-container>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary ms-auto" [disabled]="isModalLoading">
                        {{isEdit ? 'تعديل' : 'إضافة'}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>