import { SidebarMenuService } from './../../../services/sidebar-menu';
import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/services/auth.service';
@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
  toggleStatus: boolean = true
  isMouseOvered: boolean = false
  isProfileLoading: boolean = false
  innerWidth: any;
  userProfile: any = undefined
  menuItems: any = []

  constructor(private authService: AuthService, private sidebarMenuService: SidebarMenuService) {
    this.getUserData()
    this.menuItems= this.sidebarMenuService.retrieveSidebarItems()
    this.innerWidth = window.innerWidth;
    if(innerWidth < 720) {
      this.toggleStatus = false
      this.isMouseOvered = false
    }
  }

  ngOnInit(): void {
  }

  sidebarDismissed() {
    this.toggleStatus ? this.toggleStatus = false : ''
  }

  logout() {
    this.authService.logout()
  }

  mobileNavigateThenCloseSidebar() {
    this.innerWidth = window.innerWidth;
    if(innerWidth < 720) {
      this.toggleStatus = false
      this.isMouseOvered = false
    }
  }

  getUserData() {
    this.isProfileLoading = true    
      let subscription: Subscription = this.authService.getProfile()
      .pipe(finalize(() => {this.isProfileLoading = false}))
      .subscribe((res: any) => {
        this.userProfile = res['data']
        subscription.unsubscribe()
      })
  }


}
