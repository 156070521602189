import { SnackService } from './../../../../services/snack.service';
import { CompaniesService } from './../../../../services/resources/companies';
import { NgForm } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { SquareBracketsEncoder } from 'src/encoders/square-brakets-encoder';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from 'src/services/security';
import { AttachmentsService } from 'src/services/resources/attachments';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {
  @ViewChild('closeModalBtn', {static: false}) closeModalBtn: ElementRef | undefined;
  @ViewChild('closeAcceptionModalBtn', {static: false}) closeAcceptionModalBtn: ElementRef | undefined;
  @ViewChild('closeRejectionModalBtn', {static: false}) closeRejectionModalBtn: ElementRef | undefined;
  @ViewChild('closeResetStepsModalBtn', {static: false}) closeResetStepsModalBtn: ElementRef | undefined;

  isPageLoading: boolean = false
  isGettingAttachment: boolean = false
  isAttachmentLoading: boolean = false
  isModalLoading: boolean = false

  activeId: number = -1
  specificItem: any = {}
  defaultSpecificItem: any = {
    first_name:'',     
  }

  constructor(private defaultService: CompaniesService,  private securityService: SecurityService, activatedRoute: ActivatedRoute, private attachmentsService: AttachmentsService, private router: Router, private snackService: SnackService) {
    let idParam = activatedRoute.snapshot.paramMap.get('id')
      this.securityService.checkIfNumber(idParam)
      this.activeId = Number(idParam);
      this.getSpecific(this.activeId)
   }

  ngOnInit(): void {

  }

  getAttachment(attachmentObject: any, type: string) {
    this.isAttachmentLoading = true
    this.attachmentsService.getAttachment(attachmentObject.attachment_url)
    .pipe(finalize(()=>{this.isAttachmentLoading = false}))
    .subscribe(
      (res: Blob) => {      
          let attachment = res
          let file = new Blob([attachment], {type: 'application/pdf'})
          let fileUrl = URL.createObjectURL(file) 
          
          switch(type) {
            case 'view':
              window.open(fileUrl)
            break;            
            case 'download':
              let title = `${this.specificItem.company_name} - ${attachmentObject.title}.pdf`
              let a         = document.createElement('a');
              a.href        = fileUrl; 
              a.target      = '_blank';
              a.download    = title;
              document.body.appendChild(a);
              a.click();
            break;
          }
          this.snackService.showInformation("تمت المعالجة", "تم فتح الملف المرفق بلسان جديد بالمتصفح.")
          this.closeModal('attachmentModal')
      }
    
    )
  }

  closeModal(modalName: string): void {
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    if(modalName == 'attachmentModal') {
      this.closeModalBtn?.nativeElement.dispatchEvent(event);
    } else if(modalName == 'acceptionModal') {
      this.closeAcceptionModalBtn?.nativeElement.dispatchEvent(event);
    } else if(modalName == 'rejectionModal') {
      this.closeRejectionModalBtn?.nativeElement.dispatchEvent(event);
    } else if(modalName == 'resetStepsModal') {
      this.closeResetStepsModalBtn?.nativeElement.dispatchEvent(event);
    }
  }

  getSpecific(id: number): void {
    this.isPageLoading = true
    this.defaultService.getSpecific(id)
    .pipe(finalize(()=> { this.isPageLoading = false}))
    .subscribe(res => {
      this.specificItem = res['data']
    })
  }

  onSubmit(form: NgForm): void {
    this.edit(form, this.activeId)
  }

  onSuccess(): void {
    this.specificItem = {...this.defaultSpecificItem}
    this.isPageLoading = false
    this.router.navigateByUrl("dashboard/companies")
  }

  add(form: NgForm): void {
    this.isPageLoading = true
    this.defaultService.post(form.value)
      .pipe(finalize(() => { this.isPageLoading = false }))
      .subscribe(() => {
        this.onSuccess()
      })
  }

  edit(form: NgForm, id: number): void {
    this.isPageLoading = true
    this.defaultService.put(id, form.value)
      .pipe(finalize(() => { this.isPageLoading = false }))
      .subscribe(() => {
        this.onSuccess()
      })
  }

  updateStatus(status: string): void {
    this.isModalLoading = true
    let data = {status: -1}
    switch (status) {
      case 'accept':
        data.status = 2
      break
      case 'reject':
        data.status = 3
      break
    }

    this.defaultService.put(this?.specificItem?.id, data)
    .pipe(finalize(() => {
      this.isModalLoading = false
      if(status == 'accept') {
        this.closeModal('acceptionModal')
      } else if (status == 'reject') {
        this.closeModal('rejectionModal')
      }
    }))
    .subscribe(() => {
      this.getSpecific(this.activeId)
    })
  }

  resetSteps(): void {
    this.isModalLoading = true
    this.defaultService.put(this.activeId + '/reset-steps', undefined)
      .pipe(finalize(() => { this.isModalLoading = false; this.closeModal('resetStepsModal')}))
      .subscribe(() => {
        this.onSuccess()
      })
  }

}