<ng-container *ngIf="specificItem?.type">
  <div class="container my-2">
    <div class="row gy-4 align-items-center">
      <div class="col-6 text-end">
        <img src="/assets/img/system-logo.png" width="50%">
      </div>
      <div class="col-6 text-start">
        <h5 class="mb-0">رسالة من الموقع الإلكتروني</h5>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="col-4 col-md-4 mb-3">
        <h6 class="mb-0">
          <small class="d-block text-center">نوع الرسالة</small>
          <span
            class="w-100 d-block mt-2 border border-dark rounded text-center px-3 py-2"
            [class.text-danger]="specificItem?.type == 'شكوى'" [class.text-success]="specificItem?.type == 'إقتراح'"
          >
            {{ specificItem?.type }}
        </span>
        </h6>
      </div>
      <div class="col-4 col-md-4 mb-3">
        <h6 class="mb-0">
          <small class="d-block text-center">المرسل</small>
          <span
            class="w-100 d-block mt-2 border border-dark rounded text-center text-dark px-3 py-2"
          >
            {{ specificItem?.name }}
        </span>
        </h6>
      </div>
      <div class="col-4 col-md-4 mb-3">
        <h6 class="mb-0">
          <small class="d-block text-center">البريد الإلكتروني</small>
          <a
            class="w-100 d-block mt-2 border border-dark rounded text-center text-dark px-3 py-2"
            [href]="'mailto:' + specificItem?.email"
          >
            {{ specificItem?.email }}
        </a>
        </h6>
      </div>                                    
      
      <div class="col-12 col-md-12 mb-2">
        <h6 class="mb-0">
          <small class="d-block">عنوان الرسالة</small>
          <span
            class="w-100 d-block mt-2 border border-dark text-dark rounded px-3 py-2"
          >
            {{ specificItem?.type }}
          </span>
        </h6>
      </div>
      
      <div class="col-12 col-md-12 mb-2">
        <h6 class="mb-0">
          <small class="d-block">الرسالة</small>
          <span
            style="min-height: 40vh;line-height: 2.5em;"
            class="w-100 d-block mt-2 border border-dark text-dark rounded p-3"
          >
            {{ specificItem?.message }}
          </span>
        </h6>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3">
        <p class="mb-1" style="margin-right: 30px"><b>الإعتماد</b></p>
        <img id="seal" style="transform: rotateX('-80deg');" src="assets/img/digital-stamp.png" width="125px" height="125px" alt="الإعتماد الإلكتروني">
      </div>
    </div>
  </div>
</ng-container>