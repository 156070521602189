import { Injectable } from '@angular/core';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class PhotoGalleryService extends CentralService {

  protected resource: string = "gallery/photos";

}
