import { NgForm } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { SquareBracketsEncoder } from 'src/encoders/square-brakets-encoder';
import { ContactUsService } from 'src/services/resources/contact-us';

@Component({
  selector: 'app-contact-box-sheet',
  templateUrl: './contact-box-sheet.component.html',
  styleUrls: ['./contact-box-sheet.component.scss']
})

export class ContactBoxSheetComponent implements OnInit {
  isPageLoading: boolean = false

  itemsPerPage: number = 10;
  currentPage: number = 1;
  data: any[] = [];
  totalItems: number = 0

  filterBy: string = 'full_name'
  filterText: string = ''

  constructor(private defaultService: ContactUsService) {
   }

  ngOnInit(): void {
    this.getAll(1)
  }

  indexing(i: number) {
    return (((this.currentPage - 1) * this.itemsPerPage) + (i + 1))
  }

  getAll(page: number, filter?: any): void {
    this.isPageLoading = true

    // let params: HttpParams = new HttpParams({
    //     encoder: new SquareBracketsEncoder()
    // });

    let params = new HttpParams
    params = params.append("sort", "-id")
    params = params.append("page", page)    

    if(filter) {
      let key = Object.keys(filter)[0]
      let value = filter[key]
      params = params.append(key, value)
    }

    this.defaultService.getAll(params)
    .pipe(finalize(()=> { this.isPageLoading = false}))
    .subscribe(res => {
      this.currentPage = page
      this.data = res['data']
      this.totalItems = res['meta']['total']
    })
  }

  changePage(page: number): void {
    this.getAll(page)
  }

  filter(searchForm: NgForm): void {
    if(searchForm.value['searchInput']) {
      this.getAll(1, {reference_number: searchForm.value['searchInput']})
    }
  }

  delete(id: number): void {
    if (confirm("هل انت متأكد من رغبتك بحذف العنصر؟")) {
      this.defaultService.delete(id).subscribe(
        () => {
          this.getAll(this.currentPage)
        }
      )
    }
  }

}