import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SidebarMenuService {
  pages: sidebarItem[]
  constructor() {
    this.pages = [
      {
        icon: "bi bi-building",
        unique_name: "",
        display_name: "إدارة الشركات",
        custom_title: "",
        link: "companies"
      },
      {
        icon: "bi bi-boxes",
        unique_name: "",
        display_name: "إدارة المشاريع",
        custom_title: "",
        link: "projects"
      },
      {
        icon: "bi bi-card-heading",
        unique_name: "",
        display_name: "صندوق التوصل",
        custom_title: "",
        link: "contact-box"
      },
      {
        icon: "bi bi-collection-play-fill",
        unique_name: "",
        display_name: "إدارة المحتوى",
        custom_title: "",
        link: "media"
      },
      {
        icon: "bi bi-sliders2-vertical",
        unique_name: "",
        display_name: "إعدادت النظام",
        custom_title: "",
        link: "configurations"
      }
    ]
  }

  retrieveSidebarItems(): sidebarItem[] {
    return this.pages;
  }
}

export class sidebarItem {
  icon: string = ""
  unique_name: string = ""
  display_name: string = ""
  custom_title: string = ""
  link: string = ""
}
