import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from './../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private TOKEN = 'token_access';
  private LOGIN_URL = 'login';
  private LOGOUT_URL = 'logout';
  private PROFILE_URL = 'profile'

  public USER_INFORMATIOM = {}

  constructor(private http: HttpClient, private router:Router) { }

    postAuth(formData: any, auth_type: string): Observable<any> {
      let url = ''
      switch (auth_type) {
        case 'login': url = this.LOGIN_URL
        break;
      }

      return this.http.post(`${environment?.api}/${url}`, formData)
        .pipe(
          map((reply:any) => {            
            localStorage.setItem(this.TOKEN, reply['data']['token_access']);
            return this.getToken();
          })
        )
    }

    userInformation(): any {
      // if(localStorage.getItem("USER")) {
      //    return JSON.parse(atob(localStorage.getItem("USER") || ""))
      // }
    }

    getProfile(): any {
      return this.http.get(`${environment?.api}/${this.PROFILE_URL}`)
        .pipe(
          map((reply:any) => {            
            return reply
          })
        )
    }

    isLoggedIn(): boolean {
      return !!this.getToken();
    }

    removeTokens(): void {
      localStorage.removeItem(this.TOKEN);
    }

    getToken(): string {
      return localStorage.getItem(this.TOKEN)!;
    }

    logout(): void {
      this.http.post(`${environment?.api}/${this.LOGOUT_URL}`, undefined).subscribe(()=>{})
      this.removeTokens();
      this.router.navigate(['/login']);
    }
}
