import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  isProfileLoading: boolean = false
  userProfile: any = undefined

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.getUserData()
  }

  getUserData() {
    this.isProfileLoading = true    
      let subscription: Subscription = this.authService.getProfile()
      .pipe(finalize(() => {this.isProfileLoading = false}))
      .subscribe((res: any) => {
        this.userProfile = res['data']
        subscription.unsubscribe()
      })
  }

}
