import { NgForm } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { SquareBracketsEncoder } from 'src/encoders/square-brakets-encoder';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from 'src/services/security';
import { AttachmentsService } from 'src/services/resources/attachments';
import { ContactUsService } from 'src/services/resources/contact-us';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-print-contact-box-details',
  templateUrl: './print-contact-box-details.component.html',
  styleUrls: ['./print-contact-box-details.component.scss']
})
export class PrintContactBoxDetailsComponent implements OnInit {

  isPageLoading: boolean = false
  isGettingAttachment: boolean = false

  activeId: number = -1
  specificItem: any = {}
  defaultSpecificItem: any = {}

  constructor(private defaultService: ContactUsService,  private securityService: SecurityService, activatedRoute: ActivatedRoute, private attachmentsService: AttachmentsService, private router: Router, private titleService: Title) {
    let idParam = activatedRoute.snapshot.paramMap.get('id')
      this.securityService.checkIfNumber(idParam)
      this.activeId = Number(idParam);
      this.getSpecific(this.activeId)
   }

  ngOnInit(): void {

  }

  getAttachment(url: string) {
    
    
  }

  getSpecific(id: number): void {
    this.isPageLoading = true
    this.defaultService.getSpecific(id)
    .pipe(finalize(()=> { this.isPageLoading = false}))
    .subscribe(res => {
      this.specificItem = res['data']
      let title = `صندوق إعماري مدينتي بنغازي ودرنة - الموقع الإلكتروني - ${this.specificItem.type} من ${this.specificItem.name}`
      this.titleService.setTitle(title);
      setTimeout(()=>{window.print()}, 500)
      
    })
  }

  onSubmit(form: NgForm): void {
    this.edit(form, this.activeId)
  }

  onSuccess(): void {
    this.specificItem = {...this.defaultSpecificItem}
    this.isPageLoading = false
    this.router.navigateByUrl("dashboard/companies")
  }

  add(form: NgForm): void {
    this.isPageLoading = true
    this.defaultService.post(form.value)
      .pipe(finalize(() => { this.isPageLoading = false }))
      .subscribe(() => {
        this.onSuccess()
      })
  }

  edit(form: NgForm, id: number): void {
    this.isPageLoading = true
    this.defaultService.put(id, form.value)
      .pipe(finalize(() => { this.isPageLoading = false }))
      .subscribe(() => {
        this.onSuccess()
      })
  }

}