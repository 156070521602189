import { Component, OnInit } from '@angular/core';
import { SidebarMenuService } from './../../../services/sidebar-menu';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  menuItems: any = []

  constructor(private sidebarMenuService: SidebarMenuService) {
    this.menuItems= sidebarMenuService.retrieveSidebarItems()
   }

  ngOnInit(): void {}

}
