import { ProjectsService } from './../../../../services/resources/projects';
import { NgForm } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { SquareBracketsEncoder } from 'src/encoders/square-brakets-encoder';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from 'src/services/security';

@Component({
  selector: 'app-project-add-edit',
  templateUrl: './project-add-edit.component.html',
  styleUrls: ['./project-add-edit.component.scss']
})
export class PtojectAddEditComponent implements OnInit {
  @ViewChild('closeModalBtn', {static: false}) closeModalBtn: ElementRef | undefined;

  isPageLoading: boolean = false

  isEdit: boolean = false
  activeId: number = -1
  specificItem: any = {}
  defaultSpecificItem: any = {status: {value: undefined}, sector: {value: undefined}, city: {id: undefined}}

  filterBy: string = 'name'
  filterText: string = ''

  constructor(private defaultService: ProjectsService,  private securityService: SecurityService, activatedRoute: ActivatedRoute, private router: Router) {

    let idParam = activatedRoute.snapshot.paramMap.get('id')
    if (!idParam) {
      this.isEdit = false
      this.isPageLoading = false
      this.specificItem = {...this.defaultSpecificItem}
    } else {
      this.isEdit = true
      this.securityService.checkIfNumber(idParam)
      this.activeId = Number(idParam);
      this.getSpecific(this.activeId)
    }
   }

  ngOnInit(): void {
    if(!this.isEdit){
      this.specificItem = {...this.defaultSpecificItem}
    }
  }

  getSpecific(id: number): void {
    this.isPageLoading = true
    this.defaultService.getSpecific(id)
    .pipe(finalize(()=> { this.isPageLoading = false}))
    .subscribe(res => {
      this.specificItem = res['data']
    })
  }

  onSubmit(form: NgForm): void {
    const formData = new FormData()
    if(!this.isEdit) {form.value.attachment = this.specificItem.attachment}    
    Object.entries(form.value).forEach((input: any)=> {
      formData.append(input[0], input[1])
    })
    this.isEdit ? this.edit(form.value, this.activeId) : this.add(formData)
  }

  onSuccess(): void {
    this.specificItem = {...this.defaultSpecificItem}
    this.isPageLoading = false
    this.router.navigateByUrl("dashboard/projects")
  }

  add(form: any): void {
    this.isPageLoading = true 
    this.defaultService.post(form)
      .pipe(finalize(() => { this.isPageLoading = false }))
      .subscribe(() => {
        this.onSuccess()
      })
  }

  edit(form: any, id: number): void {
    this.isPageLoading = true
    this.defaultService.put(id, form)
      .pipe(finalize(() => { this.isPageLoading = false }))
      .subscribe(() => {
        this.onSuccess()
      })
  }

  appendAttachment(event: any) {
    let file = event.target.files
    if(file && file[0]) {
      this.specificItem.attachment = file[0]
    }
  }

  selectedCoordsChanged(pin: { lat: any; lng: any; }): void {
    this.specificItem.latitude = pin.lat
    this.specificItem.longitude = pin.lng
  }

}