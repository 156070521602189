import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private router: Router) { }

  checkIfNumber(input: any) {
    if (input % 1 !== 0 && input % 1 !== 0) {
      alert("Really! Nice try (-_-)");
      this.router.navigateByUrl("/")
    }
  }

}
