<div [class.d-none]="(preview != '' && preview != 'undefined/') || isLoading">
  <label class="w-100 pb-5 rounded border" style="background: #eff0f2;cursor: pointer;">
      <img style="height:150px !important" src="assets/img/no-img.jpg" class="img-fluid mx-auto d-block object-fit-cover" alt="اختر الصورة">
      <h6 class="text-center text-black-50"><b>اختر صورة</b></h6>
      <input
        style="cursor: pointer;"
        class="form-control w-75 mx-auto pt-2"
        type="file"
        accept="image/*"
        [required]="preview == ''"
        (change)="selectFiles($event)"
      />
    </label>
</div>

<div [class.d-none]="!isLoading" class="text-center">
  <div class="card">
    <div class="card-body">
      <app-spinner></app-spinner>
      <p><b>جاري رفع الصورة ...</b></p>
    </div>
  </div>
</div>

<ng-container *ngIf="preview && preview != 'undefined/'">
  <div>
    <img [src]="preview" class="preview img-thumbnail img-fluid w-100 object-fit-cover" style="height: 250px;cursor: pointer;" />
  </div>
</ng-container>

<div *ngIf="message.length" class="alert alert-secondary my-3" role="alert">
  <ul *ngFor="let msg of message; let i = index">
    <li>{{ msg }}</li>
  </ul>
</div>
