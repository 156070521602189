import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as Leaf from 'leaflet';

@Component({
  selector: 'app-leaf-map',
  templateUrl: './leaf-map.component.html',
  styleUrls: ['./leaf-map.component.scss']
})
export class LeafMapComponent implements OnInit, AfterViewInit {

  @Input() mapHeight: string | undefined;
  @Input() long: string | undefined;
  @Input() lat: string | undefined;

  @Output() selectedCoord = new EventEmitter<any>();

  map: any;
  lastMarker = {};

  iconOnline = Leaf.icon({
    iconUrl: 'assets/img/map-pin.png',
    iconSize: [25, 33],
  })

  constructor() { 
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.selectLocation()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if((changes.long.previousValue == '' && changes.long.currentValue) && (changes.lat.previousValue == '' && changes.lat.currentValue)) {      
      let pin = {lat: changes.lat.currentValue, lng: changes.long.currentValue}
      this.addMarker(pin)
    }    
  }

  private initMap(): void {
    this.map = Leaf.map('map', {
      center: [31, 17],
      zoom: 7,
    });

    const tiles = Leaf.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 20,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    tiles.addTo(this.map);
  }

  private selectLocation() {
    this.map.on('click', (e: { latlng: any; }) => {
      let coord = e.latlng;
      let lat = coord.lat;
      let lng = coord.lng;
      let pin = { lat: lat, lng: lng, status: 1 }
      this.addMarker(pin)
    });
  }


  addMarker(pin: { lat: any; lng: any; status?: number; }): void {
    setTimeout(()=>{
      if (this.lastMarker != undefined) {
        this.map.removeLayer(this.lastMarker);
      };
      this.lastMarker = Leaf.marker([pin.lat, pin.lng], { title: 'ATM', alt: 'ATM', icon: this.iconOnline }).addTo(this.map);
      this.selectedCoord.emit(pin)
    }, 250);
  }

}
