<div class="container-fluid">
    <div class="row gy-4">
        <div class="col-12">
            <h3 class="mt-4">إدارة المحتوى</h3><hr>
        </div>
        <div class="col-6 col-lg-2">
            <a class="text-dark" routerLink="news">
                <div class="card shadow-sm hover-shadow transition-fast statistics-card hover-img-show-hide">
                    <div class="card-body px-2 py-5 text-center">
                        <h6 class="mb-0">الأخبار والمستجدات</h6>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-6 col-lg-2">
            <a class="text-dark" routerLink="about-us">
                <div class="card shadow-sm hover-shadow transition-fast statistics-card hover-img-show-hide">
                    <div class="card-body px-2 py-5 text-center">
                        <h6 class="mb-0">عن الصندوق</h6>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-6 col-lg-2">
            <a class="text-dark" routerLink="gallery">
                <div class="card shadow-sm hover-shadow transition-fast statistics-card hover-img-show-hide">
                    <div class="card-body px-2 py-5 text-center">
                        <h6 class="mb-0">معرض الصور</h6>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-6 col-lg-2">
            <a class="text-dark" routerLink="video-gallery">
                <div class="card shadow-sm hover-shadow transition-fast statistics-card hover-img-show-hide">
                    <div class="card-body px-2 py-5 text-center">
                        <h6 class="mb-0">معرض الفيديو</h6>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-6 col-lg-2">
            <a class="text-dark" routerLink="studies">
                <div class="card shadow-sm hover-shadow transition-fast statistics-card hover-img-show-hide">
                    <div class="card-body px-2 py-5 text-center">
                        <h6 class="mb-0">الدراسات والأبحاث</h6>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-6 col-lg-2">
            <a class="text-dark" routerLink="reports">
                <div class="card shadow-sm hover-shadow transition-fast statistics-card hover-img-show-hide">
                    <div class="card-body px-2 py-5 text-center">
                        <h6 class="mb-0">التقارير السنوية</h6>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-6 col-lg-2">
            <a class="text-dark" routerLink="decisions">
                <div class="card shadow-sm hover-shadow transition-fast statistics-card hover-img-show-hide">
                    <div class="card-body px-2 py-5 text-center">
                        <h6 class="mb-0">القرارات والتشريعات</h6>
                    </div>
                </div>
            </a>
        </div>                                                                                        
    </div>
</div>
