<ng-container *ngIf="isLoading || (!isLoading && data.length == 0)">
    <input
    type="text"
    disabled
    [ngClass]="cssClasses"
    [required]="isRequired ? true : null"
    [placeholder]="isLoading ? 'جاري التحميل ...' : (data.length == 0 ? 'لا توجد بيانات لعرضها!':'')">
</ng-container>
 <ng-container *ngIf="!isLoading && data.length">
    <select [formControl]="childControl" [ngClass]="cssClasses" [ngModel]="currentValue" required>
        <option value="" disabled>الرجاء الإختيار</option>
        <ng-container *ngFor="let item of data">
            <ng-container *ngIf="isLoading">
                <option value="loading">جاري التحميل ...</option>
            </ng-container>
            <ng-container *ngIf="!isLoading">
                <option [value]="optionValue ? item[optionValue] : item">
                    {{item[optionText]}}
                </option>
            </ng-container>
        </ng-container>
    </select>
</ng-container>
