<div class="row align-items-center">
  <div class="col-lg-7 text-center text-md-end">
    <h3 class="mt-4 mb-0">
      <a routerLink="../" class="link-dark"> صندوق التواصل </a>>
      <h3 class="mt-4 mb-0 d-inline">
        <a routerLink="./" class="link-dark"> تفاصيل الرسالة </a>
      </h3>
    </h3>
  </div>
  <div class="col-lg-5 text-lg-start text-center">
    <h3 class="mt-4 mb-0">
      <div class="dropdown d-inline">
        <button class="btn btn-outline-dark rounded-pill ms-3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          خيارات
        </button>
        <ul class="dropdown-menu py-0">
          <li>
            <button
            class="dropdown-item d-inline-flex py-3 text-danger"
            (click)="delete()"
            >
              <i class="bi bi-trash ps-1"></i>
              حذف الرسالة
            </button>
          </li>
        </ul>
      </div>
      <a
        class="btn btn-outline-dark rounded-pill py-1 ms-3 d-none d-md-inline pe-2"
        routerLink="print"
        target="_blank"
      >
        <i class="bi bi-printer ps-1"></i>
        طباعة
      </a>
    </h3>
  </div>
</div>
<hr />
<div class="row">
  <div class="col-12">
    <div
      class="card hover-shadow shadow-sm mb-5"
      style="border-radius: 10px !important"
    >
      <div class="card-body py-lg-4 px-lg-3 px-0">
        <ng-container *ngIf="isPageLoading">
          <div class="text-center py-5">
            <div class="spinner-grow" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <div [class.d-none]="isPageLoading">
            <ng-container>
              <div class="container m-3">
                <div class="row gy-4">
                  <div class="col-12 col-md-4 mb-3">
                    <h6 class="mb-0">
                      <small class="d-block text-center">نوع الرسالة</small>
                      <span
                        class="w-100 d-block mt-2 border border-dark rounded text-center px-3 py-2"
                        [class.text-danger]="specificItem?.type == 'شكوى'" [class.text-success]="specificItem?.type == 'إقتراح'"
                      >
                        {{ specificItem?.type }}
                    </span>
                    </h6>
                  </div>
                  <div class="col-12 col-md-4 mb-3">
                    <h6 class="mb-0">
                      <small class="d-block text-center">المرسل</small>
                      <span
                        class="w-100 d-block mt-2 border border-dark rounded text-center text-dark px-3 py-2"
                      >
                        {{ specificItem?.name }}
                    </span>
                    </h6>
                  </div>
                  <div class="col-12 col-md-4 mb-3">
                    <h6 class="mb-0">
                      <small class="d-block text-center">البريد الإلكتروني</small>
                      <a
                        class="w-100 d-block mt-2 border border-dark rounded text-center text-dark px-3 py-2"
                        [href]="'mailto:' + specificItem?.email"
                      >
                        {{ specificItem?.email }}
                    </a>
                    </h6>
                  </div>                                    
                  
                  <div class="col-12 col-md-12 mb-2">
                    <h6 class="mb-0">
                      <small class="d-block">عنوان الرسالة</small>
                      <span
                        class="w-100 d-block mt-2 border border-dark text-dark rounded px-3 py-2"                        
                      >
                        {{ specificItem?.type }}
                      </span>
                    </h6>
                  </div>
                  
                  <div class="col-12 col-md-12 mb-2">
                    <h6 class="mb-0">
                      <small class="d-block">الرسالة</small>
                      <span
                      style="min-height: 40vh;"
                        class="w-100 d-block mt-2 border border-dark text-dark rounded px-3 py-2"
                        style="min-height: 40vh;line-height: 2.5em;"
                      >
                        {{ specificItem?.message }}
                      </span>
                    </h6>
                  </div>                  
                                                    



                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
