<div class="container-fluid">
    <div class="row gy-4 mb-5">
        <div class="col-lg-12">
            <div class="row gy-4 gy-lg-2">
                <div class="col-12">
                    <h3 class="mt-4">لوحة التحكم</h3><hr>
                </div>
                  <ng-container *ngFor="let item of menuItems">
                      <div class="col-6 col-xl-2 my-3">
                          <a [routerLink]="item.link" [title]="item?.custom_title || item?.display_name">
                              <div class="card shadow-sm hover-shadow transition-fast statistics-card hover-img-show-hide">
                                  <div class="card-body px-0 py-3 py-xxl-3 text-center">
                                      <p class="mb-1"><i [ngClass]="item?.icon" style="font-size: 2em;"></i></p>
                                      <h6 style="margin-top: -3px"><small>{{item?.display_name}}</small></h6>
                                  </div>
                              </div>
                          </a>
                      </div>
                   </ng-container>
            </div>
        </div>
    </div>
</div>
