<ng-container *ngIf="specificItem?.company_name">
  <div class="container my-2">
    <div class="row">
      <div class="col-6 text-end">
        <img src="/assets/img/system-logo.png" width="50%">
      </div>
      <div class="col-6 text-start">
        <h6 class="mb-0">
          <small>بيانات شركة مسجلة بالنظام الإلكتروني</small>
        </h6>
      </div>
      <div class="col-12">
        <hr>
      </div>
    </div>
    <div class="row text-center mb-4 pb-0 align-items-center mt-2">
      <div class="col-8 text-end mb-3 mb-lg-4">
        <h4 class="mb-0">
          <small>{{ specificItem?.company_name }}</small>
        </h4>
      </div>
      <div class="col-4 text-start mb-4">
        <h5 class="mb-0">
          <span class="badge border border-dark text-dark rounded-pill px-3 py-2">
            <small>{{specificItem?.status?.name}}</small>
          </span>
        </h5>
      </div>
      </div>
      <div class="row">
      <div class="col-6 mb-3">
        <h6 class="mb-0">
          <small class="d-block mb-2">الرقم المرجعي</small>
          <span
            class="badge border border-dark text-dark rounded w-100 py-2"
          >
            {{ specificItem?.reference_number }}
          </span>
        </h6>
      </div>
      <div class="col-6 mb-3">
        <h6 class="mb-0">
          <small class="d-block mb-2">نوع النشاط</small>
          <span
            class="badge border border-dark text-dark rounded w-100 py-2"
          >
            {{ specificItem?.activity_type?.name }}
          </span>
        </h6>
      </div>
      <div class="col-6 mb-3">
        <h6 class="mb-0">
          <small class="d-block mb-2">الرقم الآلي</small>
          <span
            class="badge border border-dark text-dark rounded w-100 py-2"
          >
            {{ specificItem?.registration_code }}
          </span>
        </h6>
      </div>
      <div class="col-6 mb-3">
        <h6 class="mb-0">
          <small class="d-block mb-2">تاريخ التسجيل</small>
          <span
            class="badge border border-dark text-dark rounded w-100 py-2"
          >
            {{ specificItem?.created_at }}
          </span>
        </h6>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <div class="row pb-2">
          <div class="col-12 mb-2 mt-4">
            <h5 class="border-bottom pb-3">
              <small class="d-block mb-2">البيانات الأساسية</small>
            </h5>
          </div>
          <div class="col-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">تاريخ التأسيس</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.basic_info?.establishing_date}}</span>
            </h6>
          </div> 
          <div class="col-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">سنوات الخبرة</small> 
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.basic_info?.years_of_experience}}</span>
            </h6>
          </div>  
          <div class="col-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">عدد الشركاء</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.basic_info?.stockholders_number}}</span>
            </h6>
          </div>
          <div class="col-12 col-md-12 text-end my-2">
            <h6>
              <small class="d-block mb-2">التخصصات: </small>
              <ng-container *ngFor="let item of specificItem?.basic_info?.specialties">
                <small class="mx-1">
                  <span class="badge badge bg-dark text-end rounded-pill d-inline">
                    {{item?.is_other ? item?.other : item?.name}}
                  </span>
                  </small>
              </ng-container>
            </h6>
          </div>
        </div>
      </div>
      <div class="col-12 mt-4">
        <div class="row pb-2">
          <div class="col-12 mb-2 mt-4">
            <h5 class="border-bottom pb-3">
              <small>البيانات المالية</small>
            </h5>
          </div>
          <div class="col-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">قيمة رأس المال المكتتب به</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.financial_info?.capital_subscribed_amount}}</span>
            </h6>
          </div>  
          <div class="col-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">قيمة رأس المال المدفوع</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.financial_info?.capital_paid_amount}}</span>
            </h6>
          </div>
          <div class="col-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">رقم الحساب</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.financial_info?.account_number}}</span>
            </h6>
          </div> 
          <div class="col-6 text-end my-2">
            <h6>
              <small class="d-block mb-2">المصرف</small> 
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.financial_info?.bank_name}}</span>
            </h6>
          </div>  
          <div class="col-6 text-end my-2">
            <h6>
              <small class="d-block mb-2">الفرع</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.financial_info?.bank_branch}}</span>
            </h6>
          </div>

        </div>
      </div>
      <div class="col-12">
        <div class="row pb-2">
          <div class="col-12 mb-2 mt-4">
            <h5 class="border-bottom pb-3">
              <small>بيانات المفوض</small>
            </h5>
          </div>
          <div class="col-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">الاسم</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.delegate_name}}</span>
            </h6>
          </div>                      
          <div class="col-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">الصفة القانونية</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.delegate_adjective}}</span>
            </h6>
          </div>
          <div class="col-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">رقم الهاتف</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.delegate_phone}}</span>
            </h6>
          </div>                                    
        </div>
      </div>
      <div class="col-12">
        <div class="row mb-3 pb-2">
          <div class="col-12 mb-2 mt-4">
            <h5 class="border-bottom pb-3">
              <small>بيانات إضافية</small>
            </h5>
          </div>
          <div class="col-4 col-md-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">الواتس اب</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">
                  {{specificItem?.additional_info?.whatsapp_phone}}
              </span>
            </h6>
          </div>
          <div class="col-4 col-md-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">هاتف الشركة</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">
                  {{specificItem?.additional_info?.company_phone}}
              </span>
            </h6>
          </div>
          <div class="col-4 col-md-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">البريد الإلكتروني</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">
                  {{specificItem?.additional_info?.company_email}}
              </span>
            </h6>
          </div>
          <div class="col-4 col-md-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">الموقع الإلكتروني</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">
                  {{specificItem?.additional_info?.website}}
              </span>
            </h6>
          </div>
          <div class="col-4 col-md-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">المفوض العام</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.additional_info?.delegate_general_office}}</span>
            </h6>
          </div>  
          <div class="col-4 col-md-4 text-end my-2">
            <h6>
              <small class="d-block mb-2">اثبات هوية المفوض</small>
              <span class="badge border border-dark text-dark rounded w-100 py-2">{{specificItem?.additional_info?.delegate_id_card}}</span>
            </h6>
          </div>                       
          <div class="col-12 col-md-12 text-end my-2">
            <h6>
              <small class="d-block mb-2">العنوان</small> 
              <span class="badge border border-dark text-dark rounded w-100 py-2" style="white-space: normal;">{{specificItem?.additional_info?.company_address}}</span>
            </h6>
          </div>                                                                    
        </div>
      </div>                  
      <div class="col-lg-12">
        <div class="row mb-3 pb-4">
          <div class="col-12 mb-2 mt-4">
            <h5 class="pb-2">
              <small>الكادر الفني</small>
            </h5>
            <div class="table-responsive">
              <table
                class="table table-hover text-center table-lg border"
              >
                <thead>
                  <tr>
                    <th class="border-top-0 border-right-0"></th>
                    <th>
                      <small>المهندسين</small>
                    </th>
                    <th>
                      <small>الفنيين</small>
                    </th>
                    <th>
                      <small>الإداريين</small>
                    </th>
                    <th>
                      <small>العمالة العادية</small>
                    </th>
                    <th>
                      <small>الإجمالي</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      <small>العمالة الوطنية</small>
                    </th>
                    <td>
                      {{specificItem?.artistic_staff?.national_engineers}}
                    </td>
                    <td>
                      {{specificItem?.artistic_staff?.national_technicians}}
                    </td>
                    <td>
                      {{specificItem?.artistic_staff?.national_administrators}}
                    </td>
                    <td>
                      {{specificItem?.artistic_staff?.national_workers}}
                    </td>
                    <td>
                      {{specificItem?.artistic_staff?.total_national_workers}}
                    </td>                                
                  </tr>
                  <tr>
                    <th>                      
                      <small>العمالة الوافدة</small>
                    </th>
                    <td>
                      {{specificItem?.artistic_staff?.expatriate_engineers}}
                    </td>
                    <td>
                      {{specificItem?.artistic_staff?.expatriate_technicians}}
                    </td>
                    <td>
                      {{specificItem?.artistic_staff?.expatriate_administrators}}
                    </td>
                    <td>
                      {{specificItem?.artistic_staff?.expatriate_workers}} 
                    </td>
                    <td>
                      {{specificItem?.artistic_staff?.total_expatriate_workers}} 
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>                                                         
        </div>
      </div>                                                  
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3">
        <p class="mb-1" style="margin-right: 30px"><b>الإعتماد</b></p>
        <img id="seal" style="transform: rotateX('-80deg');" src="assets/img/digital-stamp.png" width="125px" height="125px" alt="الإعتماد الإلكتروني">
      </div>
    </div>
  </div>
</ng-container>