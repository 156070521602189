import { AboutUsComponent } from './pages/media-center/about-us/about-us.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { NewsComponent } from './pages/media-center/news/news.component';
import { PhotoGalleryComponent } from './pages/media-center/photo-gallery/photo-gallery.component';
import { VideoGalleryComponent } from './pages/media-center/video-gallery/video-gallery.component';
import { MediaIndexComponent } from './pages/media-center/media-index/media-index.component';
import { ContactBoxSheetComponent } from './pages/contact-box/contact-box-sheet/contact-box-sheet.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PrintCompanyDetailsComponent } from './pages/companies/print-company-details/print-company-details.component';
import { CompanyDetailsComponent } from './pages/companies/company-details/company-details.component';
import { CompaniesSheetComponent } from './pages/companies/companies-sheet/companies-sheet.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from 'src/guards/auth.guard';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { ConfigurationsIndexComponent } from './pages/configurations/configurations-index/configurations-index.component';
import { CitiesComponent } from './pages/configurations/cities/cities.component';
import { PrintContactBoxDetailsComponent } from './pages/contact-box/print-contact-box-details/print-contact-box-details.component';
import { ContactBoxDetailsComponent } from './pages/contact-box/contact-box-details/contact-box-details.component';
import { StudiesComponent } from './pages/media-center/studies/studies.component';
import { ReportsComponent } from './pages/media-center/reports/reports.component';
import { DecisionsComponent } from './pages/media-center/decisions/decisions.component';
import { PtojectAddEditComponent } from './pages/projects/project-add-edit/project-add-edit.component';


const MediaRoutes: Routes = [
  {path: '', component: MediaIndexComponent},
  {path: 'gallery', component: PhotoGalleryComponent},
  {path: 'news', component: NewsComponent},
  {path: 'about-us', component: AboutUsComponent},
  {path: 'video-gallery', component: VideoGalleryComponent},
  {path: 'studies', component: StudiesComponent},
  {path: 'reports', component: ReportsComponent},
  {path: 'decisions', component: DecisionsComponent},
]

const dashboardRoutes: Routes = [
  {path: '', component: DashboardComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'projects', component: ProjectsComponent},
  {path: 'projects/add', component: PtojectAddEditComponent},
  {path: 'projects/:id', component: PtojectAddEditComponent},

  {path: 'companies', component: CompaniesSheetComponent},
  {path: 'companies/:id', component: CompanyDetailsComponent},

  {path: 'contact-box', component: ContactBoxSheetComponent},
  {path: 'contact-box/:id', component: ContactBoxDetailsComponent},
  
  {path: 'media', children: MediaRoutes},


  {path: 'configurations', component: ConfigurationsIndexComponent},
  {path: 'configurations/cities', component: CitiesComponent},

]

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'dashboard', component: DashboardLayoutComponent, children: dashboardRoutes, canActivate: [AuthGuard]},
  {path: 'dashboard/companies/:id/print', component: PrintCompanyDetailsComponent},
  {path: 'dashboard/contact-box/:id/print', component: PrintContactBoxDetailsComponent},


];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
