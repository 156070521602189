<div class="container-fluid">
    <div class="row gy-4">
        <div class="col-12">
            <h3 class="mt-4">إعدادت النظام</h3><hr>
        </div>
        <div class="col-6 col-lg-3 col-xxl-2">
            <a class=" text-dark" routerLink="cities">
                <div class="card shadow-sm hover-shadow transition-fast statistics-card hover-img-show-hide">
                    <div class="card-body px-2 py-5 text-center">
                        <h5 class="mb-0">المدن</h5>
                    </div>
                </div>
            </a>
        </div>                                                       
    </div>
</div>
