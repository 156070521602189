<div class="wrapper">
  <div class="container">
    <main class="row align-content-center h-100-vh">
      <div class="col-lg-4 mx-auto">
        <div class="card rounded-lg border hover-shadow shadow-sm transition-fast">
          <div class="card-body p-5">
            <form class="w-100" #loginForm="ngForm" ngNativeValidate (ngSubmit)="login(loginForm)">
              <div class="">
                <img class="" src="assets/img/logo.png" alt="شعار صندوق اعمار مدينتي بنغازي ودرنة" width="150" />
              </div>
              <h5 class="mt-3 mb-1"><small>صندوق اعمار مدينتي بنغازي ودرنة</small></h5>
              <h5 class="text-muted"><small>الدخول للنظام</small></h5>
              <div class="my-4 border-top border-bottom py-4">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control rounded"
                    id="floatingInput"
                    placeholder="example@email.com"
                    name="email"
                    [ngModel]
                    required
                  />
                  <label for="floatingInput">البريد الإلكتروني</label>
                </div>
                
                <div class="form-floating rounded mt-4">
                  <input
                    type="password"
                    class="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    name="password"
                    [ngModel]
                    required
                  />
                  <label for="floatingPassword">كلمة المرور</label>
                </div>

              </div>
              <button
              class="w-100 btn btn-lg btn-primary"
              [disabled]="isLoading"
            >
              <ng-container *ngIf="!isLoading; else loadingSpinner">
                <div class="my-1">تسجيل الدخول</div>
              </ng-container>
              <ng-template #loadingSpinner>
                <span
                  class="spinner-border spinner-border-sm mt-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              </ng-template>
            </button>              
            </form>
          </div>
        </div>
        <p class="mt-3 text-secondary">
          <small>تنفيذ <a class="link-dark" href="https://www.sgts.ly/" target="_blank">شركة المجموعة الذكية للحلول التقنية</a></small>
        </p>
      </div>
    </main>
  </div>
</div>
