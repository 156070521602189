<div class="row">
  <div class="col-6">
    <h3 class="mt-4 mb-0">
      <a routerLink="../" class="link-dark"> الشركات </a>>
      <h3 class="mt-4 mb-0 d-inline">
        <a routerLink="./" class="link-dark">
          جدول الشركات
        </a>
      </h3>
    </h3>
  </div>
  <div class="col-6 text-start"></div>
</div>
<hr />
<div class="row">
  <div class="col-12 text-start mb-3">
    <form
      #searchForm="ngForm"
      (ngSubmit)="filter(searchForm)"
      (change)="searchForm.value['searchInput'] ? '' : getAll(1)"
    >
      <input
        class="form-control rounded-pill hover-shadow shadow-sm"
        type="search"
        placeholder="ادخل الرقم المرجعي ..."
        ngModel
        name="searchInput"
        [(ngModel)]="filterText"
      />
    </form>
  </div>
  <div class="col-12">
    <div
      class="card hover-shadow shadow-sm"
      style="border-radius: 10px !important"
    >
      <div class="card-body p-0">
        <ng-container *ngIf="isPageLoading">
          <div class="text-center py-5">
            <div class="spinner-grow" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isPageLoading && data.length == 0">
          <div
            class="alert alert-warning mb-0"
            style="border-radius: 10px !important"
          >
            <i class="bi bi-info-circle ps-2"></i>
            عذرًا، ولكن لا توجد بيانات لعرضها.
          </div>
        </ng-container>
        <ng-container *ngIf="!isPageLoading && data.length != 0">
          <div class="table-responsive">
            <table
              class="table table-hover table-striped mb-0 text-center table-lg align-middle"
            >
              <thead>
                <tr>
                  <td width="80">#</td>
                  <td>الرقم المرجعي</td>
                  <td width="200">الشركة</td>
                  <td>النوع</td>
                  <td>المفوض</td>
                  <td>الحالة</td>
                  <td>الرقم الآلي</td>
                  <td>تاريخ التسجيل</td>
                  <td width="10%">خيارات</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let i = index;
                    let item of data
                      | paginate
                        : {
                            itemsPerPage: itemsPerPage,
                            currentPage: currentPage,
                            totalItems: totalItems
                          }
                  "
                >
                <td width="80"><small>{{indexing(i)}}</small></td>
                <td><small>{{ item?.reference_number }}</small></td>
                <td><small>{{ item?.company_name }}</small></td>
                <td><small>{{ item?.activity_type?.name }}</small></td>
                <td>
                  <div><small>{{ item?.delegate_name }}</small></div>
                  <div><small>{{ item?.delegate_adjective }}</small></div>
                  <div><small>{{ item?.delegate_phone }}</small></div>
                </td>
                <td>
                  <small class="d-block">{{ item?.status?.name }}</small>
                  <small class="d-block">خطوة: {{ item?.registration_step }}</small>
                </td>
                <td><small>{{ item?.registration_code }}</small></td>
                <td><small>{{ item?.created_at }}</small></td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <a
                        [routerLink]="item['id']"
                        class="btn btn-sm btn-light hover-shadow shadow-sm ms-2"
                        title="بيانات الشركة"
                      >
                        <i class="bi bi-file-earmark-medical"></i>
                      </a>
                      <!-- <button
                        (click)="delete(item['id'])"
                        class="btn btn-sm btn-light hover-shadow shadow-sm text-danger"
                        title="حذف"
                      >
                        <i class="bi bi-trash"></i>
                      </button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col-9 col-md-6 text-end">
                  <pagination-controls
                    (pageChange)="changePage($event)"
                    previousLabel="السابق"
                    nextLabel="التالي"
                  ></pagination-controls>
                </div>
                <div class="col-3 col-md-6 text-start"><small>الإجمالي: {{ totalItems }}</small></div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
