import { Injectable } from '@angular/core';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class ContactUsService extends CentralService {

  protected resource: string = "contact-us";

}
