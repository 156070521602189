import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CentralService } from './central.service';

@Injectable({
  providedIn: 'root'
})

export class UploadService extends CentralService {

  protected resource: string = "upload";

  upload(file: File): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('image', file);

    return this.http.post(this.fullURL, formData)
  }

  getFiles(): Observable<any> {
    return this.http.get(`${this.fullURL}/files`);
  }
}
