import { NgForm } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { SquareBracketsEncoder } from 'src/encoders/square-brakets-encoder';
import { PhotoGalleryService } from 'src/services/resources/photo-gallery';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {
  @ViewChild('closeModalBtn', {static: false}) closeModalBtn: ElementRef | undefined;

  isPageLoading: boolean = false
  isModalLoading: boolean = false

  itemsPerPage: number = 15;
  currentPage: number = 1;
  data: any[] = [];
  totalItems: number = 0

  isEdit: boolean = false
  activeId: number = -1
  specificItem: any = {}
  defaultSpecificItem: any = {}

  filterBy: string = 'name'
  filterText: string = ''

  isPasswordShown: boolean = false

  constructor(private defaultService: PhotoGalleryService) {

   }

  ngOnInit(): void {
    this.getAll(1)
    if(!this.isEdit){
      this.specificItem = this.defaultSpecificItem
    }
  }

  indexing(i: number) {
    return (((this.currentPage - 1) * this.itemsPerPage) + (i + 1))
  }
  
  getAll(page: number, filter?: any): void {
    this.isPageLoading = true

    let params: HttpParams = new HttpParams({
        encoder: new SquareBracketsEncoder()
    });

    params = params.append("perPage", this.itemsPerPage)    
    params = params.append("page", page)
    params = params.append("sort", 'country_id')

    if(filter) {
      let filtersArray = Object.entries(filter)
      for(let i of filtersArray) {
        let filterKey = i[0]
        let filterValue = String(i[1])
        params = params.append(`filter[${filterKey}]`, filterValue)
      }      
    }

    this.defaultService.getAll(params)
    .pipe(finalize(()=> { this.isPageLoading = false}))
    .subscribe(res => {
      this.currentPage = page
      this.data = res['data']
      this.totalItems = res['meta']['total']
    })
  }

  getSpecific(id: number): void {
    this.isModalLoading = true
    this.defaultService.getSpecific(id)
    .pipe(finalize(()=> { this.isModalLoading = false}))
    .subscribe(res => {
      this.specificItem = res['data']
    })
  }

  changePage(page: number): void {
    this.getAll(page)
  }

  filter(searchForm: NgForm): void {
    if(searchForm.value['searchInput']) {
      this.getAll(1, {name: searchForm.value['searchInput']})
    }
  }

  openModal(isEdit: boolean, id?: any): void {    
    if (isEdit) {
      this.isEdit = true
      this.activeId = id
      this.getSpecific(id || -1)
    } else {
      this.specificItem = {...this.defaultSpecificItem}      
    }
  }

  closeModal(): void {
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    this.closeModalBtn?.nativeElement.dispatchEvent(event);
    
  }

  onModalSubmit(form: NgForm): void {
    const formData = new FormData()
    if(!this.isEdit) {form.value.attachment = this.specificItem.attachment}    
    Object.entries(form.value).forEach((input: any)=> {
      formData.append(input[0], input[1])
    })
    this.isEdit ? this.edit(form.value, this.activeId) : this.add(formData)
  }

  onModalSuccess(): void {
    this.specificItem = {}
    this.isModalLoading = false
    this.getAll(this.currentPage)
    this.closeModal()
  }

  add(form: any): void {
    this.isModalLoading = true
    this.defaultService.post(form)
      .pipe(finalize(() => { this.isModalLoading = false }))
      .subscribe(() => {
        this.onModalSuccess()
      })
  }

  edit(form: any, id: number): void {
    this.isModalLoading = true
    this.defaultService.put(id, form)
      .pipe(finalize(() => { this.isModalLoading = false }))
      .subscribe(() => {
        this.onModalSuccess()
      })
  }

  delete(id: number): void {
    if (confirm("هل انت متأكد من رغبتك بحذف العنصر؟")) {
      this.defaultService.delete(id).subscribe(
        () => {
          this.getAll(this.currentPage)
        }
      )
    }
  }

  appendAttachment(event: any) {
    let file = event.target.files
    if(file && file[0]) {
      this.specificItem.attachment = file[0]
    }
    
  }

}
