import { Injectable } from '@angular/core';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class ProjectsService extends CentralService {

  protected resource: string = "projects";

}
