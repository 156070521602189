<div class="row">
  <div class="col-6">
    <h3 class="mt-4 mb-0">
      <a routerLink="../" class="link-dark"> إدارة المحتوى </a>>
      <h3 class="mt-4 mb-0 d-inline">
        <a routerLink="./" class="link-dark">المشاريع</a>
      </h3>
    </h3>
  </div>
  <div class="col-6 text-start">
    <button
      class="btn hover-shadow rounded-pill px-3 btn-sm btn-outline-dark ms-2 mt-4 hover-shadow"
      title="إضافة"
      routerLink="add"
    >
      <i class="bi bi-plus-lg pt-2 ps-2"></i>
      إضافة جديد
    </button>
  </div>
</div>
<hr />
<div class="row">
  <div class="col-12">
    <div
      class="card hover-shadow shadow-sm"
      style="border-radius: 10px !important"
    >
      <div class="card-body p-0">
        <ng-container *ngIf="isPageLoading">
          <div class="text-center py-5">
            <div class="spinner-grow" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isPageLoading && data.length == 0">
          <div
            class="alert alert-warning mb-0"
            style="border-radius: 10px !important"
          >
            <i class="bi bi-info-circle ps-2"></i>
            عذرًا، ولكن لا توجد بيانات لعرضها.
          </div>
        </ng-container>

        <ng-container *ngIf="!isPageLoading && data.length != 0">
          <div class="table-responsive">
              <table class="table table-hover table-striped mb-0 text-center table-lg align-middle">
                  <thead>
                      <tr>
                          <td width="80">#</td>
                          <td>العنوان</td>
                          <td>المدينة</td>
                          <td>القطاع</td>
                          <td>الحالة</td>
                          <td>تاريخ الإضافة</td>
                          <td width="10%">خيارات</td>
                      </tr>
                  </thead>
                  <tbody>
                      <tr
                          *ngFor="let i = index; let item of data | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }">
                          <td width="80"><small>{{indexing(i)}}</small></td>
                          <td>{{item['title']}}</td>
                          <td>{{item['city']['name']}}</td>
                          <td>{{item['sector']['name']}}</td>
                          <td>{{item['status']['name']}}</td>
                          <td><div dir="ltr">{{item['created_at']}}</div></td>
                          <td>
                              <div class="d-flex justify-content-center">
                                  <button [routerLink]="'./' + item['id']"
                                      class="btn btn-sm btn-light hover-shadow shadow-sm ms-2" title="تعديل"
                                      data-bs-toggle="modal" data-bs-target="#addEditModal">
                                      <i class="bi bi-pencil-square"></i>
                                  </button>  
                                  <button (click)="delete(item['id'])"
                                      class="btn btn-sm btn-light hover-shadow shadow-sm text-danger"
                                      title="حذف">
                                      <i class="bi bi-trash"></i>
                                  </button>
                              </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="text-center mt-4">
              <div class="container-fluid">
                  <div class="row">
                      <div class="col-6 text-end">
                          <pagination-controls (pageChange)="changePage($event)" previousLabel="السابق"
                              nextLabel="التالي"></pagination-controls>
                      </div>
                      <div class="col-6 text-start">
                          الإجمالي: {{totalItems}}
                      </div>
                  </div>
              </div>
          </div>
      </ng-container>
      </div>
    </div>
  </div>
</div>
