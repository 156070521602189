import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-select',
  templateUrl: './dynamic-select.component.html',
  styleUrls: ['./dynamic-select.component.scss']
})
export class DynamicSelectComponent implements OnInit {

  @Input() resource: string = "";
  @Input() cssClasses: string = ""
  @Input() currentValue: string = "loading"
  @Input() optionText: string = ""
  @Input() optionValue: string = ""
  @Input() isRequired: boolean = false


  childControl = new FormControl();

  data: any = []
  isLoading: boolean = false

  constructor(public http: HttpClient, el: ElementRef) {
  }

  writeValue(value: any) {    
    this.childControl.setValue(value);
  }
  
  registerOnChange(fn: (value: any) => void) {
    this.childControl.valueChanges.subscribe(fn);
  }

  registerOnTouched() {}

  ngOnInit(): void {
    this.getData()    
  }

  get fullURL(): string {
    return environment.api + "/" + this.resource;
  }

  getData(): any {
    let params = {perPage: 10000}
    this.isLoading = true
    this.http.get(this.fullURL, {params})
    .pipe(finalize(()=>{this.isLoading = false}))
    .subscribe(
      (response: any) => {                
        this.data = response['data'] ? response['data'] : response        
        if(this.data.length && !this.currentValue) {
          this.currentValue = ''
          // this.currentValue = response['data'][0][this.optionValue]
        }
      }
    )
  }

}
