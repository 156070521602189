import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class CentralService {

  protected resource: string = "";

  get fullURL(): string {
    return  environment.api + "/" + this.resource;
  }

  constructor(public http: HttpClient, public sanitizer: DomSanitizer) {
  }

  getAll(params?: any): Observable<any> {      
    return (params) ? this.http.get(this.fullURL, {params}) : this.http.get(this.fullURL);
  }

  getSpecific(id: string | number, params?: any): Observable<any> {
    return this.http.get(this.fullURL + '/' + id, {params});
  }

  patch(id: string | number, data: any, params?: any): Observable<any> {
    return this.http.patch(this.fullURL + '/' + id, data, {params});
  }

  put(id: string | number, data: any, params?: any): Observable<any> {
    return this.http.put(this.fullURL + '/' + id, data, {params});
  }

  delete(id: string | number, params?: any): Observable<any> {
    return this.http.delete(this.fullURL + '/' + id, {params});
  }

  post(data: any, params?: any): Observable<any> {
    return this.http.post(this.fullURL, data, {params})
  }
  
  postSpecific(id: string | number, data: any, params?: any): Observable<any> {
    return this.http.post(this.fullURL + '/' + id, data, {params})
  }

}
