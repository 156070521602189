import { Injectable } from '@angular/core';
import { NgxNotificationMsgService, NgxNotificationStatusMsg  } from 'ngx-notification-msg';

@Injectable({
  providedIn: 'root'
})
export class SnackService {

  constructor(private readonly ngxNotificationMsgService: NgxNotificationMsgService) { }

  showSuccess(header: string, text: string) {
    this.ngxNotificationMsgService.open({
      status: NgxNotificationStatusMsg.SUCCESS,
      displayIcon: true,
      delay: 6000,
      header: header,
      messages: [text]
    });
    // Snackbar.show({ text: text, pos: 'top-center', showAction: false, duration:  3500, backgroundColor: '#3cab30', customClass: 'rounded-pill'});
  }

  showError(header: string, text: string) {
    this.ngxNotificationMsgService.open({
      status: NgxNotificationStatusMsg.FAILURE,
      displayIcon: true,
      delay: 6000,      
      header: header,
      messages: [text]
    });
    // Snackbar.show({ text: text, pos: 'top-center', showAction: false, duration:  3500,  backgroundColor: '#cc2838', customClass: 'rounded-pill'});
  }

  
  showInformation(header: string, text: string) {
    this.ngxNotificationMsgService.open({
      status: NgxNotificationStatusMsg.INFO,
      displayIcon: true,
      delay: 6000,      
      header: header,
      messages: [text]
    });
    // Snackbar.show({ text: text, pos: 'top-center', showAction: false, duration:  3500,  backgroundColor: '#cc2838', customClass: 'rounded-pill'});
  }


}
