import { Injectable } from '@angular/core';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class DecisionsService extends CentralService {

  protected resource: string = "decisions";

}
