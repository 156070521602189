import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UploadService } from 'src/services/uploader.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent implements OnInit {
  @Input() preview: string = '';
  @Input() placeholder: string = '';

  @Output() activeImageName = new EventEmitter<any>();

  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];

  imageInfos?: Observable<any>;
  isLoading: boolean = false

  constructor(private uploadService: UploadService) { }

  ngOnInit(): void {
    // this.imageInfos = this.uploadService.getFiles();
  }

  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;

    this.preview = '';
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {

        this.uploadFiles()
        // const reader = new FileReader();

        // reader.onload = (e: any) => {
        //   this.previews.push(e.target.result);
        // };

        // reader.readAsDataURL(this.selectedFiles[i]);
      }
    } else {
      this.preview = '';
      this.activeImageName.emit(event.image_name)
    }
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };

    if (file) {
      this.isLoading = true
      this.uploadService.upload(file).subscribe(
        (event: any) => {
          
          let image = `${event.base_url}/${event.image_name}`
          this.preview = image

          this.activeImageName.emit({base_url:event.base_url, image_name: event.image_name})
          this.isLoading = false

          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            const msg = 'Uploaded the file successfully: ' + file.name;
            this.message.push(msg);
            // this.imageInfos = this.uploadService.getFiles();
          }
        },
        (err: any) => {
          this.progressInfos[idx].value = 0;
          const msg = 'Could not upload the file: ' + file.name;
          this.message.push(msg);
        }
      );
    }
  }

  uploadFiles(): void {
    this.message = [];
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }
}
