import { SnackService } from '../../../../services/snack.service';
import { NgForm } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from 'src/services/security';
import { AttachmentsService } from 'src/services/resources/attachments';
import { ContactUsService } from 'src/services/resources/contact-us';

@Component({
  selector: 'app-contact-box-details',
  templateUrl: './contact-box-details.component.html',
  styleUrls: ['./contact-box-details.component.scss']
})
export class ContactBoxDetailsComponent implements OnInit {

  isPageLoading: boolean = false
  isGettingAttachment: boolean = false
  isAttachmentLoading: boolean = false
  isModalLoading: boolean = false

  activeId: number = -1
  specificItem: any = {}
  defaultSpecificItem: any = {}

  constructor(private defaultService: ContactUsService,  private securityService: SecurityService, activatedRoute: ActivatedRoute, private router: Router, private snackService: SnackService) {
    let idParam = activatedRoute.snapshot.paramMap.get('id')
      this.securityService.checkIfNumber(idParam)
      this.activeId = Number(idParam);
      this.getSpecific(this.activeId)
   }

  ngOnInit(): void {

  }

  getSpecific(id: number): void {
    this.isPageLoading = true
    this.defaultService.getSpecific(id)
    .pipe(finalize(()=> { this.isPageLoading = false}))
    .subscribe(res => {
      this.specificItem = res['data']
    })
  }

  delete(): void {
    if (confirm("هل انت متأكد من رغبتك بحذف العنصر؟")) {
      this.defaultService.delete(this.activeId).subscribe(
        () => {
          this.router.navigateByUrl("dashboard/contact-box")
        }
      )
    }
  }
 

}