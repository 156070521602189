import { Router } from '@angular/router';
import { SnackService } from './../../../services/snack.service';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoading = false

  constructor(private authService: AuthService, private router: Router) {
    if(authService.isLoggedIn()){
      router.navigateByUrl('/dashboard')
    }
  }

  ngOnInit(): void {
  }

  login(loginForm: NgForm): void {
    const formValue = loginForm.value;
    
    if (loginForm.valid) {
      this.isLoading = true;
      this.authService.postAuth(formValue, 'login')
        .pipe(finalize(() => this.isLoading = false))
        .subscribe((response: Response) => {
          this.router.navigateByUrl('/dashboard');
        })
    }
  }
}
