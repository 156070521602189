import { SnackService } from './snack.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
// import { AuthService } from './auth.service';

@Injectable() 
export class HttpErrorInterceptor implements HttpInterceptor {

    errorTitla: any;
    errorMessage: any;
    isGetRequest: boolean | undefined;
    constructor(private router: Router,
                private snackService: SnackService,
                private authService: AuthService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        request = request.clone({headers: request.headers.set('Accept-Language', 'ar')});
        if (this.authService.getToken()) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${this.authService.getToken()}`
                }
            });
        }
        
        return next.handle(request)
            .pipe(
                map((res: any) => {
                    if (res["status"] >= 200 && res["status"] <= 205) {                      
                        let successTitle = "تم التنفيذ"
                        let successMessage = res?.body?.message || "لقد تم تنفيذ العملية بنجاح"
                        !request["url"].includes("/login") && !request["url"].includes("/me") && request["method"] !== "GET" ? this.snackService.showSuccess(successTitle, successMessage): res;
                        return res;
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    request["method"] === "GET" ? this.isGetRequest = true: this.isGetRequest = false;
                    switch(error.status){
                        case 0:
                            this.errorMessage = "حدثت مشكلة بالإتصال بالشبكة";
                            break;
                        case 301:
                            this.errorMessage = "العنصر الذي تحاول الوصول إليه تم نقله إلى أخر";
                            break;
                        case 400: 
                            this.errorMessage = error?.error?.message || "طلب خاطئ";                            
                            break;
                        case 401:
                            this.errorMessage = error?.error?.error;
                            if(request["url"].includes("/logout")) {this.authService.removeTokens; this.router.navigate(['/login']);}
                            else {this.authService.logout()}
                            break;
                        case 403: 
                            if(error.error.error) {
                                this.errorMessage = error.error.error
                            } else {
                                this.errorMessage = "ليس لديك الصلاحيات لإتمام هذا الإجراء.";
                            }
                            // this.router.navigate(['/login']);

                            break;
                        case 404:
                            if(request["url"].includes("/login")) {
                                this.errorTitla = "بيانات خاطئة"
                                this.errorMessage = "الرجاء التحقق من ادخال بيانات الدخول بشكل صحيح.";
                            } else {
                                this.errorMessage = "البيانات التي تحاول الوصول إليها غير موجودة";
                            }
                            break;
                        case 405:
                            this.errorMessage = error?.error?.message;
                            break;
                        case 422:
                            if(request["url"].includes("/auth/register")) {
                                this.errorTitla = error?.error?.message
                                this.errorMessage = Object.entries(error?.error?.errors)[0][1] || error?.error?.message
                            } else {
                                this.errorMessage = Object.entries(error?.error?.errors)[0][1] || error?.error?.message
                            }
                            break;
                        case 429:
                            this.errorMessage = "لقد أرسلت الكثير من الطلبات في وقت قصير، يرجى الانتظار لمدة دقيقة والمحاولة مرة أخرى.";
                            break;
                        case 500:
                            this.errorMessage = "هناك خطأ ما بالخادم. إذا تكررت المشكلة، فيرجى إخبارنا!";
                            break;
                        default:
                            if (error.status >= 300 && error.status <= 399 ) {
                                this.errorMessage = "هناك خطأ ما في إعادة التوجيه";
                            } else if (error.status >= 400 && error.status <= 499 ) {
                                this.errorMessage = "Client-side error";
                            } else if (error.status >= 500 && error.status <= 599 ) {
                                this.errorMessage = "Server-side error";
                            } else {
                            }
                            break;
                        }
                    console.log(error);
                    
                    this.snackService.showError(this.errorTitla || "Alert!", this.errorMessage + " (CODE: " + error?.status?.toString() + ")");
                    return throwError(error);
                }))
    }

    private addToken(request: HttpRequest<any>, token: string){
        return request.clone({
            setHeaders: {
                'Authorization':`Bearer ${token}` 
            }
        });
    }
}